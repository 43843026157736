/**
 * @author jaeho.lee104 on 2023. 11. 25..
 */
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {AdminAPI} from "../network/AdminAPI";
import {ResponseCodes} from "../network/ResponseCodes";
import FlexBox from "../styledcomponents/FlexBox";
import NewH1 from "../styledcomponents/text/NewH1";
import NewH3 from "../styledcomponents/text/NewH3";
import {Colors} from "../styledcomponents/Styles";
import TextField from "../components/TextField";
import NewButton from "../styledcomponents/NewButton";
import VerticalDivider from "../styledcomponents/VerticalDivider";
import NewP2 from "../styledcomponents/text/NewP2";
import FilePickerBox from "../components/FilePickerBox";
import {CertificationModel} from "../model/CertificationModel";
import Image from "../styledcomponents/Image";

const CertificationManageFragment = () => {

    const navigate = useNavigate()
    const [certificationNameKr, setCertificationNameKr] = useState("")
    const [certificationDescriptionKr, setCertificationDescriptionKr] = useState("")
    const [certificationNameEn, setCertificationNameEn] = useState("")
    const [certificationDescriptionEn, setCertificationDescriptionEn] = useState("")
    const [file, setFile] = useState<File | null>(null)
    const [certifications, setCertifications] = useState<Array<CertificationModel>>([])
    const [refresh, setRefresh] = useState(0)

    useEffect(() => {
        AdminAPI.getCertifications()
            .then((res) => {
                let data = res.data.data
                setCertifications(data || [])
            })
    }, [refresh])

    function handleAddCertification() {
        if (!certificationNameKr || certificationNameKr.length === 0) {
            alert("인증서 한글 등록 필요")
            return
        }
        if (!certificationNameEn || certificationNameEn.length === 0) {
            alert("인증서 영문 등록 필요")
            return
        }
        if (!file) {
            alert("인증서 이미지 파일 등록 필요")
            return;
        }
        AdminAPI.addCertification(
            certificationNameKr,
            certificationDescriptionKr,
            certificationNameEn,
            certificationDescriptionEn,
            file
        ).then((res) => {
            let responseCode = ResponseCodes.of(res?.data?.code)
            if (responseCode.isSuccess()) {
                setCertificationNameKr("")
                setCertificationNameEn("")
                setCertificationDescriptionKr("")
                setCertificationDescriptionEn("")
                setRefresh(refresh + 1)
            } else {
                alert(res || "등록 실패")
            }
        }).catch((e) => {
            alert("등록 실패")
        })
    }

    function handleDeleteCertification(certificationId: string) {
        AdminAPI.deleteCertification(
            certificationId
        ).then((res) => {
            let responseCode = ResponseCodes.of(res?.data?.code)
            if (responseCode.isSuccess()) {
                setRefresh(refresh + 1)
            } else {
                alert(res || "삭제 실패")
            }
        }).catch((e) => {
            alert("삭제 실패")
        })
    }

    return (
        <FlexBox
            padding={"40px"}
            width={"1200px"}
            margin={"0 auto"}
            flexDirection={"column"}
            overflow={"auto"}>
            <NewH1
                marginTop={"40px"}>
                [ 인증서 관리 ]
            </NewH1>
            <NewH3
                marginTop={"40px"}>
                # 인증서 등록
            </NewH3>
            <FlexBox
                borderRadius={"12px"}
                width={"100%"}
                flexWrap={"wrap"}
                minHeight={"200px"}
                padding={"20px 40px 30px 40px"}
                border={`1px solid ${Colors.GRAY_4}`}
                marginTop={"20px"}
                flexDirection={"column"}>
                <TextField
                    width={"100%"}
                    label={"인증서(한글)"}
                    labelFontWight={700}
                    labelFontSize={"16px"}
                    marginTop={"20px"}
                    value={certificationNameKr}
                    onChange={(e) => {
                        setCertificationNameKr(e.target.value)
                    }}
                    type={"text"}
                />
                <TextField
                    width={"100%"}
                    label={"인증서 설명(한글)"}
                    labelFontWight={700}
                    labelFontSize={"16px"}
                    marginTop={"20px"}
                    value={certificationDescriptionKr}
                    onChange={(e) => {
                        setCertificationDescriptionKr(e.target.value)
                    }}
                    type={"text"}
                />
                <TextField
                    width={"100%"}
                    label={"인증서(영어)"}
                    labelFontWight={700}
                    labelFontSize={"16px"}
                    marginTop={"20px"}
                    value={certificationNameEn}
                    onChange={(e) => {
                        setCertificationNameEn(e.target.value)
                    }}
                    type={"text"}
                />
                <TextField
                    width={"100%"}
                    label={"인증서 설명(영어)"}
                    labelFontWight={700}
                    labelFontSize={"16px"}
                    marginTop={"20px"}
                    value={certificationDescriptionEn}
                    onChange={(e) => {
                        setCertificationDescriptionEn(e.target.value)
                    }}
                    type={"text"}
                />
                <FilePickerBox
                    marginTop={"20px"}
                    labelFontWight={700}
                    label={"인증서 이미지 (png 파일만 업로드 가능)"}
                    includeExtensions={['png']}
                    labelVisible={true}
                    onChange={(file) => {
                        setFile(file)
                    }}
                />
                <FlexBox
                    marginTop={"40px"}>
                    <NewButton
                        onClick={() => {
                            handleAddCertification()
                        }}>
                        등록
                    </NewButton>
                    <NewButton
                        colorType={1}
                        marginLeft={"20px"}
                        onClick={() => {
                            setCertificationNameKr("")
                            setCertificationNameEn("")
                        }}>
                        취소
                    </NewButton>
                </FlexBox>

            </FlexBox>
            <NewH3
                marginTop={"40px"}>
                # 등록된 인증서 목록
            </NewH3>
            <FlexBox
                borderRadius={"12px"}
                width={"100%"}
                flexDirection={"column"}
                overflowY={"auto"}
                height={"fit-content"}
                minHeight={`100px`}
                padding={"24px"}
                border={`1px solid ${Colors.GRAY_4}`}
                marginTop={"20px"}>
                {certifications.map((item, index) => {
                    return <FlexBox
                        justifyContent={"space-between"}
                        margin={"8px"}
                        padding={"20px"}
                        height={"fit-content"}
                        minHeight={"160px"}
                        border={`1px solid ${Colors.GRAY_4}`}
                        borderRadius={"12px"}>
                        <FlexBox
                            alignItems={"center"}>
                            <NewH1>
                                {index + 1}
                            </NewH1>
                            <VerticalDivider
                                backgroundColor={Colors.GRAY_4}
                                height={"100%"}
                                margin={"16px"}/>
                            <Image
                                alignSelf={"center"}
                                width={"160px"}
                                height={"160px"}
                                borderRadius={"24px"}
                                border={`1px solid ${Colors.GRAY_2}`}
                                backgroundColor={Colors.GRAY_0}
                                justifyContent={"center"}
                                alignItems={"center"}
                                src={item.fileUrl}
                                alt={`company-image-${index}`}/>
                            <FlexBox
                                marginLeft={"20px"}
                                width={"100%"}
                                overflow={"auto"}
                                height={"fit-content"}
                                flexDirection={"column"}>
                                <NewP2>
                                    한글: {item.name}
                                </NewP2>
                                <NewP2>
                                    한글설명: {item.desc}
                                </NewP2>
                                <NewP2
                                    marginTop={'10px'}>
                                    영어: {item.nameEn}
                                </NewP2>
                                <NewP2>
                                    영문설명: {item.descEn}
                                </NewP2>
                                <NewButton
                                    marginBottom={'8px'}
                                    marginTop={"16px"}
                                    colorType={3}
                                    onClick={() => {
                                        handleDeleteCertification(item.id)
                                    }}>
                                    삭제
                                </NewButton>
                            </FlexBox>
                        </FlexBox>
                    </FlexBox>
                })}
            </FlexBox>
        </FlexBox>
    )
}
export default CertificationManageFragment;