/**
 * @author jaeho.lee104 on 2023. 08. 23..
 */
import CommonComponent, {CommonProps} from "./CommonComponent";
import styled from "styled-components";
import {Colors} from "./Styles";

const normalColors = [
    Colors.WHITE,
    Colors.WHITE,
    Colors.MAIN,
    Colors.WHITE,
    Colors.WHITE,
    Colors.MAIN,
    Colors.WHITE,
]
const normalBackgroundColors = [
    Colors.MAIN,
    Colors.GRAY_5,
    Colors.WHITE,
    Colors.DESTRUCTIVE_PRIMARY,
    Colors.GRAY_5,
    Colors.SECONDARY_HOVER,
    Colors.GRAY_7
]
const hoverBackgroundColors = [
    Colors.CTA_HOVER,
    Colors.GRAY_6,
    Colors.SECONDARY_HOVER,
    Colors.DESTRUCTIVE_HOVER,
    Colors.GRAY_6,
    Colors.SECONDARY_HOVER,
    Colors.GRAY_8
]
const activeBackgroundColors = [
    Colors.CTA_PRESSED,
    Colors.GRAY_6,
    Colors.SECONDARY_PRESSED,
    Colors.DESTRUCTIVE_PRESSED,
    Colors.GRAY_6,
    Colors.SECONDARY_PRESSED,
    Colors.GRAY_9
]
const disabledBackgroundColors = [
    Colors.CTA_DISABLED,
    Colors.GRAY_4,
    Colors.WHITE,
    Colors.DESTRUCTIVE_DISABLED,
    Colors.GRAY_4,
    Colors.WHITE,
    Colors.GRAY_6
]
const disabledColors = [
    Colors.WHITE,
    Colors.WHITE,
    Colors.CTA_DISABLED,
    Colors.WHITE,
    Colors.WHITE,
    Colors.CTA_DISABLED,
    Colors.WHITE
]

interface NewButtonProps extends CommonProps {
    colorType?: number
}

const NewButton = styled(CommonComponent)<NewButtonProps>`
    width: ${({width}) => width || "100%"};
    height: ${({height}) => height || "44px"};
    display: ${({display, visible}) => visible ? (display || "flex") : "none"};
    font-size: ${({fontSize}) => fontSize || "16px"};
    font-weight: ${({fontWeight}) => fontWeight || 700};
    text-align: ${({textAlign}) => textAlign || "center"};
    align-items: ${({alignItems}) => alignItems || "center"};
    justify-content: ${({justifyContent}) => justifyContent || "center"};
    border-radius: ${({borderRadius}) => borderRadius || "8px"};
    background-color: ${({backgroundColor, colorType, disabled, backgroundOnDisabled}) => disabled ?
            (backgroundOnDisabled || disabledBackgroundColors[colorType || 0]) : (backgroundColor || normalBackgroundColors[colorType || 0])};
    color: ${({
                  color,
                  colorType,
                  colorOnDisabled,
                  disabled
              }) => disabled ? (colorOnDisabled || disabledColors[colorType || 0]) : color || normalColors[colorType || 0]};
    cursor: pointer;

    &:hover {
        background-color: ${({
                                 backgroundOnHover,
                                 colorType,
                                 disabled
                             }) => disabled ? undefined : backgroundOnHover || hoverBackgroundColors[colorType || 0]};
    }

    &:active {
        background-color: ${({
                                 backgroundOnActive,
                                 colorType,
                                 disabled
                             }) => disabled ? undefined : backgroundOnActive || activeBackgroundColors[colorType || 0]};
    }
`;
export default NewButton;