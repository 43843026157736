import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../modules";
import React, {useEffect, useState} from "react";
import {AdminAPI} from "../network/AdminAPI";
import FlexBox from "../styledcomponents/FlexBox";
import NewH1 from "../styledcomponents/text/NewH1";
import NewH4 from "../styledcomponents/text/NewH4";
import {NewRadioItem} from "../components/NewRadioItem";
import {TextUtils} from "../utils/TextUtils";
import {Colors} from "../styledcomponents/Styles";
import NewP4 from "../styledcomponents/text/NewP4";
import NewH5 from "../styledcomponents/text/NewH5";
import NewP1 from "../styledcomponents/text/NewP1";
import {ColorUtils} from "../utils/ColorUtils";
import {CompanyListItem} from "../model/CompnayListItem";
import {CompanyStatus} from "../model/CompanyStatus";

const CompanyListFragment = () => {

    const {companyRefresh} = useSelector((state: RootState) => state.adminReducer, shallowEqual);
    const [companies, setCompanies] = useState<Array<CompanyListItem>>([])
    const [filteredCompanies, setFilteredCompanies] = useState<Array<CompanyListItem>>([])
    const [itemStatusFilter, setItemStatusFilter] = useState("ALL")
    const filterWidth = "fit-content"

    useEffect(() => {
        AdminAPI.getSolutionCompanies()
            .then((res) => {
                let data = res.data.data
                setCompanies(data || [])
            })
    }, [companyRefresh])

    useEffect(() => {
        let filtered = companies.filter((item) => itemStatusFilter === "ALL" ? true : item.statusCode === itemStatusFilter)
        setFilteredCompanies(filtered)
    }, [companies, itemStatusFilter]);

    return <FlexBox
        padding={"40px"}
        width={"1200px"}
        margin={"0 auto"}
        flexDirection={"column"}
        overflow={"auto"}>
        <NewH1
            marginTop={"40px"}>
            [ 기업 심사 목록 관리 ]
        </NewH1>
        <NewH4
            marginTop={"20px"}>
            필터
        </NewH4>
        <FlexBox
            flexWrap={"wrap"}
            marginTop={"10px"}
            alignItems={"center"}>
            <NewRadioItem
                width={filterWidth}
                title={"전체"}
                checked={itemStatusFilter === "ALL"}
                onClick={() => {
                    setItemStatusFilter("ALL")
                }}/>
            {Object.keys(CompanyStatus).map((item, index) => {
                return <NewRadioItem
                    width={filterWidth}
                    marginLeft={"8px"}
                    title={TextUtils.companyStatusToUiTest(item)}
                    checked={itemStatusFilter === item}
                    onClick={() => {
                        setItemStatusFilter(item)
                    }}/>
            })}
        </FlexBox>
        <FlexBox
            marginTop={"40px"}
            flexDirection={"column"}
            flexWrap={"wrap"}>
            {filteredCompanies.map((item, index) => {
                return <FlexBox
                    cursor={"pointer"}
                    onClick={() => {
                        window.open(`/company-detail?companyId=${item.companyDetailId}`)
                    }}
                    marginTop={"10px"}
                    width={"100%"}
                    height={"fit-content"}
                    overflow={"hidden"}
                    borderRadius={"16px"}
                    boxShadow={"0px 4px 8px 0px #00000014;"}
                    border={`1px solid ${Colors.GRAY_3}`}>
                    <FlexBox
                        alignItems={"center"}
                        padding={"20px"}
                        height={"100%"}
                        width={"100%"}>
                        <NewP4
                            width={"60px"}>
                            # {index + 1}
                        </NewP4>
                        <NewH5
                            whiteSpace={"normal"}
                            textOverflow={"ellipsis"}
                            overflow={"hidden"}
                            width={"200px"}
                            marginLeft={"10px"}
                            textAlign={"left"}
                            color={Colors.GRAY_8}>
                            회사명: {item.companyDetailName}
                        </NewH5>
                        <NewP1
                            whiteSpace={"normal"}
                            textOverflow={"ellipsis"}
                            overflow={"hidden"}
                            textAlign={"left"}
                            width={"150px"}
                            marginLeft={"10px"}
                            color={Colors.GRAY_8}>
                            설립연도: {item.establishedYear}
                        </NewP1>
                        <NewP1
                            whiteSpace={"normal"}
                            textOverflow={"ellipsis"}
                            overflow={"hidden"}
                            textAlign={"left"}
                            width={"150px"}
                            marginLeft={"20px"}
                            color={Colors.GRAY_8}>
                            국가: {item.countryName}
                        </NewP1>
                        <NewP1
                            marginLeft={"20px"}
                            width={"120px"}
                            color={ColorUtils.getColorByCompanyStatus(item.statusCode)}>
                            상태: {TextUtils.companyStatusToUiTest(item.statusCode)}
                        </NewP1>
                        <NewP1
                            maxWidth={"240px"}
                            whiteSpace={"nowrap"}
                            textOverflow={"ellipsis"}
                            overflow={"hidden"}
                            textAlign={"left"}
                            marginLeft={"40px"}
                            color={Colors.GRAY_8}>
                            주소: {item.businessCompanyAddress}
                        </NewP1>
                    </FlexBox>
                </FlexBox>
            })}
        </FlexBox>
    </FlexBox>
}
export default CompanyListFragment;