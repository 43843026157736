/**
 * @author jaeho.lee104 on 2023. 11. 25..
 */
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {CategoryModel} from "../model/CategoryModel";
import {AdminAPI} from "../network/AdminAPI";
import {ResponseCodes} from "../network/ResponseCodes";
import FlexBox from "../styledcomponents/FlexBox";
import NewH1 from "../styledcomponents/text/NewH1";
import NewH3 from "../styledcomponents/text/NewH3";
import {Colors} from "../styledcomponents/Styles";
import TextField from "../components/TextField";
import NewButton from "../styledcomponents/NewButton";
import VerticalDivider from "../styledcomponents/VerticalDivider";
import NewP2 from "../styledcomponents/text/NewP2";

const CategoryManageFragment = () => {

    const navigate = useNavigate()
    const [inputCategoryKr, setInputCategoryKr] = useState("")
    const [inputCategoryEn, setInputCategoryEn] = useState("")
    const [categories, setCategories] = useState<Array<CategoryModel>>([])
    const [refresh, setRefresh] = useState(0)
    const [activeModifyCategoryId, setActiveModifyCategoryId] = useState("")
    const [modifyCategoryNameKr, setModifyCategoryNameKr] = useState("")
    const [modifyCategoryNameEn, setModifyCategoryNameEn] = useState("")

    useEffect(() => {
        AdminAPI.getCategories()
            .then((res) => {
                let data = res.data.data
                setCategories(data || [])
            })
    }, [refresh])

    function handleAddCategory() {
        if (!inputCategoryKr || inputCategoryKr.length === 0) {
            alert("카테고리 한글 등록 필요")
            return
        }
        if (!inputCategoryEn || inputCategoryEn.length === 0) {
            alert("카테고리 영문 등록 필요")
            return
        }
        AdminAPI.addCategory(
            inputCategoryKr,
            inputCategoryEn
        ).then((res) => {
            let responseCode = ResponseCodes.of(res?.data?.code)
            if (responseCode.isSuccess()) {
                setInputCategoryKr("")
                setInputCategoryEn("")
                setRefresh(refresh + 1)
            } else {
                alert(res || "등록 실패")
            }
        }).catch((e) => {
            alert("등록 실패")
        })
    }

    function handleDeleteCategory(categoryId: string) {
        AdminAPI.deleteCategory(
            categoryId
        ).then((res) => {
            let responseCode = ResponseCodes.of(res?.data?.code)
            if (responseCode.isSuccess()) {
                setRefresh(refresh + 1)
            } else {
                alert(res || "삭제 실패")
            }
        }).catch((e) => {
            alert("삭제 실패")
        })
    }

    function handleModifyCategory(
        categoryId: string,
        categoryName: string,
        categoryNameEn: string
    ) {
        AdminAPI.modifyCategory(
            categoryId,
            categoryName,
            categoryNameEn
        ).then((res) => {
            let responseCode = ResponseCodes.of(res?.data?.code)
            if (responseCode.isSuccess()) {
                setRefresh(refresh + 1)
                setActiveModifyCategoryId("")
                setModifyCategoryNameKr("")
                setModifyCategoryNameEn("")
            } else {
                alert(res || "수정 실패")
            }
        }).catch((e) => {
            alert("수정 실패")
        })
    }

    return (
        <FlexBox
            padding={"40px"}
            width={"1200px"}
            margin={"0 auto"}
            flexDirection={"column"}
            overflow={"auto"}>
            <NewH1
                marginTop={"40px"}>
                [ 카테고리 관리 ]
            </NewH1>
            <NewH3
                marginTop={"40px"}>
                # 카테고리 등록
            </NewH3>
            <FlexBox
                borderRadius={"12px"}
                width={"100%"}
                flexWrap={"wrap"}
                minHeight={"200px"}
                padding={"20px 40px 30px 40px"}
                border={`1px solid ${Colors.GRAY_4}`}
                marginTop={"20px"}
                flexDirection={"column"}>
                <TextField
                    width={"400px"}
                    label={"카테고리(한글)"}
                    labelFontWight={700}
                    labelFontSize={"16px"}
                    marginTop={"20px"}
                    value={inputCategoryKr}
                    onChange={(e) => {
                        setInputCategoryKr(e.target.value)
                    }}
                    type={"text"}
                />
                <TextField
                    width={"400px"}
                    label={"카테고리(영어)"}
                    labelFontWight={700}
                    labelFontSize={"16px"}
                    marginTop={"20px"}
                    value={inputCategoryEn}
                    onChange={(e) => {
                        setInputCategoryEn(e.target.value)
                    }}
                    type={"text"}
                />
                <FlexBox
                    marginTop={"40px"}>
                    <NewButton
                        onClick={() => {
                            handleAddCategory()
                        }}>
                        등록
                    </NewButton>
                    <NewButton
                        colorType={1}
                        marginLeft={"20px"}
                        onClick={() => {
                            setInputCategoryKr("")
                            setInputCategoryEn("")
                        }}>
                        취소
                    </NewButton>
                </FlexBox>

            </FlexBox>
            <NewH3
                marginTop={"40px"}>
                # 등록된 카테고리 목록
            </NewH3>
            <FlexBox
                borderRadius={"12px"}
                width={"100%"}
                flexWrap={"wrap"}
                overflowY={"auto"}
                height={"fit-content"}
                maxHeight={"800px"}
                padding={"24px"}
                border={`1px solid ${Colors.GRAY_4}`}
                marginTop={"20px"}>
                {categories.map((item, index) => {
                    return <FlexBox
                        justifyContent={"space-between"}
                        flexDirection={"column"}
                        margin={"8px"}
                        padding={"20px"}
                        width={"240px"}
                        minHeight={"160px"}
                        border={`1px solid ${Colors.GRAY_4}`}
                        borderRadius={"12px"}>
                        <FlexBox
                            alignItems={"center"}>
                            <NewH1>
                                {index + 1}
                            </NewH1>
                            <VerticalDivider
                                backgroundColor={Colors.GRAY_4}
                                height={"100%"}
                                margin={"16px"}/>
                            <FlexBox
                                width={"100%"}
                                overflow={"auto"}
                                height={"fit-content"}
                                flexDirection={"column"}>
                                <NewP2>
                                    한글: {item.categoryName}
                                </NewP2>
                                <NewP2
                                    marginTop={'10px'}>
                                    영어: {item.categoryNameEn}
                                </NewP2>
                            </FlexBox>
                        </FlexBox>
                        <FlexBox
                            marginTop={"16px"}>
                            <NewButton
                                onClick={() => {
                                    setActiveModifyCategoryId(item.categoryId)
                                    setModifyCategoryNameKr(item.categoryName)
                                    setModifyCategoryNameEn(item.categoryNameEn)
                                }}>
                                수정
                            </NewButton>
                            <NewButton
                                colorType={3}
                                marginLeft={"20px"}
                                onClick={() => {
                                    handleDeleteCategory(item.categoryId)
                                }}>
                                삭제
                            </NewButton>
                        </FlexBox>
                        {activeModifyCategoryId === item.categoryId && <FlexBox
                            flexDirection={"column"}>
                            <TextField
                                width={"100%"}
                                label={"수정: 카테고리(한글)"}
                                labelFontWight={700}
                                labelFontSize={"16px"}
                                marginTop={"16px"}
                                value={modifyCategoryNameKr}
                                onChange={(e) => {
                                    setModifyCategoryNameKr(e.target.value)
                                }}
                                type={"text"}
                            />
                            <TextField
                                width={"100%"}
                                label={"수정: 카테고리(영어)"}
                                labelFontWight={700}
                                labelFontSize={"16px"}
                                marginTop={"16px"}
                                value={modifyCategoryNameEn}
                                onChange={(e) => {
                                    setModifyCategoryNameEn(e.target.value)
                                }}
                                type={"text"}
                            />
                            <FlexBox
                                marginTop={"16px"}>
                                <NewButton
                                    onClick={() => {
                                        if (!modifyCategoryNameKr || !modifyCategoryNameEn) {
                                            alert("수정할 카테고리 이름을 입력해주세요")
                                            return
                                        }
                                        handleModifyCategory(
                                            item.categoryId,
                                            modifyCategoryNameKr,
                                            modifyCategoryNameEn)
                                    }}>
                                    저장
                                </NewButton>
                                <NewButton
                                    colorType={1}
                                    marginLeft={"20px"}
                                    onClick={() => {
                                        setActiveModifyCategoryId("")
                                        setModifyCategoryNameKr("")
                                        setModifyCategoryNameEn("")
                                    }}>
                                    취소
                                </NewButton>
                            </FlexBox>
                        </FlexBox>}
                    </FlexBox>
                })}
            </FlexBox>
        </FlexBox>
    )
}
export default CategoryManageFragment;