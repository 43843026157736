import {UsersAPI} from "../network/UsersAPI";
import {RefreshTokenRequestBody} from "../model/RefreshTokenRequestBody";
import {ResponseCodes} from "../network/ResponseCodes";
import {LocalStorageManager} from "../LocalStorageManager";
import {Dispatch} from "redux";
import {Logger} from "../log/Logger";
import {User} from "../model/User";

/**
 * @author jaeho.lee104 on 2023. 05. 08..
 */
enum UserActionType {
    LOGIN = "users/LOGIN",
    LOGOUT = "users/LOGOUT",
    DELETE = "users/DELETE",
    REFRESH_TOKEN = "users/REFRESH_TOKEN",
    UPDATE_USER = "users/UPDATE_USER"
}

interface UserUpdateInfoAction {
    type: UserActionType.UPDATE_USER,
    user: User
}

interface UserRefreshTokenAction {
    type: UserActionType.REFRESH_TOKEN,
    signedIn: boolean
    user: User
    accessToken: string,
    refreshToken: string
}

interface UserLogoutAction {
    type: UserActionType.LOGOUT,
    signedIn: boolean
    user: User | null
    accessToken: string
    refreshToken: string
}

interface UserDeleteAction {
    type: UserActionType.DELETE,
    signedIn: boolean
    user: User | null
    accessToken: string
    refreshToken: string
}

interface UserLoginAction {
    type: UserActionType.LOGIN
    signedIn: boolean
    user: User
    accessToken: string
    refreshToken: string
}

type UserActions = UserRefreshTokenAction
    | UserLogoutAction
    | UserLoginAction
    | UserUpdateInfoAction
    | UserDeleteAction

type UserState = {
    signedIn: boolean,
    user: User | null,
    accessToken: string,
    refreshToken: string
}

const initialState: UserState = {
    signedIn: (LocalStorageManager.getUserId()?.length || 0) > 0,
    user: new User(LocalStorageManager.getUserId() || "",
        "", "", "", "", "", "", "", ""),
    accessToken: "",
    refreshToken: LocalStorageManager.getRefreshToken() || ""
};

export class Users {

    static isRefreshing = false

    static updateUser = (user: User): UserUpdateInfoAction => {
        return {
            type: UserActionType.UPDATE_USER,
            user: user
        }
    }

    static loginSuccess = (
        componentName: string,
        user: User,
        accessToken: string,
        refreshToken: string
    ) => {
        LocalStorageManager.saveRefreshToken(refreshToken)
        LocalStorageManager.saveUserId(user.userId)
        Logger.setUserId(user.userId)
        Logger.info(componentName, `login success. userId: ${user.userId}`)
        return {
            type: UserActionType.LOGIN,
            signedIn: true,
            user: user,
            accessToken: accessToken,
            refreshToken: refreshToken
        };
    }

    static logout = () => {
        LocalStorageManager.removeUserId()
        LocalStorageManager.removeRefreshToken()
        // noinspection JSIgnoredPromiseFromCall
        UsersAPI.signOut()
        return {
            type: UserActionType.LOGOUT,
            signedIn: false,
            user: null,
            accessToken: "",
            refreshToken: ""
        };
    };

    static deleteUser = () => {
        LocalStorageManager.removeUserId()
        LocalStorageManager.removeRefreshToken()
        // noinspection JSIgnoredPromiseFromCall
        UsersAPI.deleteUser()
        return {
            type: UserActionType.LOGOUT,
            signedIn: false,
            user: null,
            accessToken: "",
            refreshToken: ""
        };
    };

    static refreshToken = () => {
        if (Users.isRefreshing) {
            return
        }
        const userId = LocalStorageManager.getUserId()
        const refreshToken = LocalStorageManager.getRefreshToken()
        if (!userId || !refreshToken) {
            return {
                type: UserActionType.REFRESH_TOKEN,
                signedIn: false,
                user: null,
                accessToken: "",
                refreshToken: ""
            };
        }
        return (dispatch: Dispatch) => {
            Users.isRefreshing = true
            return UsersAPI.refreshToken(new RefreshTokenRequestBody(userId, refreshToken))
                .then(async (response) => {
                    const responseCode = ResponseCodes.of(response.data.code);
                    if (responseCode.isSuccess()) {
                        const user = await UsersAPI.getUserOrNull()
                        dispatch({
                            type: UserActionType.REFRESH_TOKEN,
                            signedIn: user !== null,
                            user: user,
                            accessToken: new Date().getTime().toString(),
                            refreshToken: refreshToken
                        })
                    } else {
                        LocalStorageManager.removeUserId()
                        LocalStorageManager.removeRefreshToken()
                        dispatch({
                            type: UserActionType.REFRESH_TOKEN,
                            signedIn: false,
                            user: null,
                            accessToken: "",
                            refreshToken: ""
                        })
                    }
                    Users.isRefreshing = false
                })
                .catch((e) => {
                    dispatch({
                        type: UserActionType.REFRESH_TOKEN,
                        signedIn: false,
                        user: null,
                        accessToken: "",
                        refreshToken: ""
                    })
                    Users.isRefreshing = false
                })
        };
    };
}

function usersReducer(state: UserState = initialState, action: UserActions): UserState {
    switch (action.type) {
        case UserActionType.LOGIN:
            return {
                signedIn: action.signedIn,
                user: action.user,
                accessToken: action.accessToken,
                refreshToken: action.refreshToken
            }
        case UserActionType.LOGOUT:
            return {
                signedIn: action.signedIn,
                user: action.user,
                accessToken: action.accessToken,
                refreshToken: action.refreshToken
            }
        case UserActionType.REFRESH_TOKEN:
            return {
                signedIn: action.signedIn,
                user: action.user,
                accessToken: action.accessToken,
                refreshToken: action.refreshToken
            }
        case UserActionType.UPDATE_USER:
            return {
                ...state,
                user: action.user
            }
        case UserActionType.DELETE:
            return {
                signedIn: action.signedIn,
                user: action.user,
                accessToken: action.accessToken,
                refreshToken: action.refreshToken
            }
        default:
            return state;
    }
}

export default usersReducer;
