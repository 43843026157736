import {useNavigate, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import FlexBox from "../styledcomponents/FlexBox";
import {Colors, Sizes} from "../styledcomponents/Styles";
import VerticalDivider from "../styledcomponents/VerticalDivider";
import NewH4 from "../styledcomponents/text/NewH4";
import HsCodeSearchFragment from "./HsCodeSearchFragment";
import ImportNoticeManageFragment from "./ImportNoticeManageFragment";

const ToolsFragment = () => {

    const [searchParams, setSearchParams] = useSearchParams()
    const [selectedMenuIndex, setSelectedMenuIndex] = useState(Number(searchParams.get('index') || '0'))
    const navigate = useNavigate()

    useEffect(() => {
        setSelectedMenuIndex(Number(searchParams.get('index') || String(selectedMenuIndex)))
    }, [searchParams])

    return (
        <FlexBox
            width={"100%"}
            minWidth={Sizes.CONTENT_WIDTH}
            height={"100%"}>
            <FlexBox
                flexDirection={"column"}
                minWidth={"240px"}
                borderRight={`2px solid ${Colors.GRAY_2}`}
                backgroundColor={Colors.WHITE}
                padding={"48px 40px"}>
                <FlexBox
                    flexDirection={"column"}>
                    <FlexBox
                        marginTop={"24px"}>
                        <VerticalDivider
                            backgroundColor={Colors.GRAY_3}
                            height={"100%"}
                            width={"4px"}/>
                        <FlexBox
                            flexDirection={"column"}
                            paddingLeft={"16px"}>
                            <NewH4
                                color={selectedMenuIndex === 0 ? Colors.GRAY_8 : Colors.GRAY_4}
                                cursor={"pointer"}
                                onClick={() => {
                                    navigate("/tools?index=0")
                                }}>
                                HS CODE 다운로드
                            </NewH4>
                            <NewH4
                                marginTop={"20px"}
                                color={selectedMenuIndex === 1 ? Colors.GRAY_8 : Colors.GRAY_4}
                                cursor={"pointer"}
                                onClick={() => {
                                    navigate("/tools?index=1")
                                }}>
                                수입신청 공지 관리
                            </NewH4>
                        </FlexBox>
                    </FlexBox>
                </FlexBox>
            </FlexBox>
            <FlexBox
                flexGrow={1}>
                {selectedMenuIndex === 0 ? <HsCodeSearchFragment/>
                    : selectedMenuIndex === 1 ? <ImportNoticeManageFragment/>
                        : <HsCodeSearchFragment/>}
            </FlexBox>
        </FlexBox>
    )
}
export default ToolsFragment;