import axios, {AxiosError, AxiosResponse} from "axios";

export const BASE_URL = "https://apis.thirdworks.co.kr";
axios.defaults.withCredentials = true;

export const createDefaultAxiosInstance = () => {
    const instance = axios.create({
        baseURL: BASE_URL,
        timeout: 50000,
    });
    instance.interceptors.response.use(
        (response: AxiosResponse<BaseResponse<any>>) => {
            const rawResponseCode = response?.data?.code
            if (rawResponseCode === "0099") {
                window.location.href = "/login";
            }
            return response
        },
        (error: AxiosError<BaseResponse<any>>) => {
            return Promise.reject(error)
        })
    return instance
}