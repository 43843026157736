/**
 * @author jaeho.lee104 on 2023. 05. 14..
 */
import moment from 'moment';
import 'moment/locale/ko'; //대한민국

export class DateFormatUtils {
    static toYYYY_MM_DD_slash(dateString: string): string {
        return moment(dateString).format("YYYY/MM/DD")
    }

    static toYYYY_MM_DD_dash(dateString: string): string {
        return moment(dateString).format("YYYY-MM-DD")
    }

    static toYYYYMMDD(dateString: string): string {
        return moment(dateString).format("YYYYMMDD")
    }

    static toYYYY_MM_DD_unit(dateString?: string, langCode: string = "kr"): string {
        if (!dateString) {
            return ""
        }
        if (langCode === "kr") {
            return moment(dateString).format("YYYY년 MM월 DD일")
        } else {
            return moment(dateString).locale("en").format("LL")
        }
    }
}