/**
 * @author jaeho.lee104 on 2024. 02. 02..
 */
import React, {useEffect, useState} from "react";
import FlexBox from "../styledcomponents/FlexBox";
import NewH1 from "../styledcomponents/text/NewH1";
import {AdminAPI} from "../network/AdminAPI";
import {useNavigate} from "react-router-dom";
import {AccountUser} from "../model/AccountUser";
import {Colors} from "../styledcomponents/Styles";
import NewH7 from "../styledcomponents/text/NewH7";
import NewP1 from "../styledcomponents/text/NewP1";
import NewH6 from "../styledcomponents/text/NewH6";

const AllUsersFragment = () => {

    const navigate = useNavigate()
    const [users, setUsers] = useState<Array<AccountUser>>([])

    useEffect(() => {
        AdminAPI.getAllUsers()
            .then((res) => {
                let data = res.data.data
                setUsers(data || [])
            })
    }, [])

    return <FlexBox
        padding={"40px"}
        width={"1200px"}
        margin={"0 auto"}
        flexDirection={"column"}
        overflow={"auto"}
        alignItems={"flex-start"}>
        <NewH1
            marginTop={"40px"}>
            전체 회원 목록
        </NewH1>
        <FlexBox
            width={"100%"}
            marginTop={"40px"}
            flexDirection={"column"}>
            {users.map((item, index) => {
                return <FlexBox
                    boxShadow={"0px 2px 16px 0px rgba(0, 0, 0, 0.05)"}
                    padding={"20px"}
                    width={"100%"}
                    border={`1px solid ${Colors.GRAY_3}}`}
                    borderRadius={"16px"}
                    marginTop={"10px"}
                    marginRight={"10px"}>
                    <NewH6
                        width={"240px"}
                        color={Colors.GRAY_7}>
                        {index}. {item.companyName}
                    </NewH6>
                    <NewP1
                        marginLeft={"10px"}
                        width={"160px"}
                        color={Colors.GRAY_8}>
                        {item.customerName}
                    </NewP1>
                    <NewH7
                        marginLeft={"10px"}
                        width={"160px"}
                        color={Colors.GRAY_7}>
                        {item.phoneNumber}
                    </NewH7>
                    <NewH7
                        whiteSpace={"nowrap"}
                        overflow={"hidden"}
                        textOverflow={"ellipsis"}
                        width={"600px"}
                        marginLeft={"10px"}
                        height={"fit-content"}
                        color={Colors.GRAY_7}>
                        {item.customerId}
                    </NewH7>
                </FlexBox>
            })}

        </FlexBox>
    </FlexBox>
}
export default AllUsersFragment;