/**
 * @author jaeho.lee104 on 2023. 11. 08..
 */
import React from "react";
import NewH1 from "../styledcomponents/text/NewH1";
import {useNavigate} from "react-router-dom";
import FlexBox from "../styledcomponents/FlexBox";

const NewHomeFragment = () => {

    const navigate = useNavigate()

    return (
        <FlexBox
            width={"100%"}
            flexDirection={"column"}
            alignItems={"center"}
            margin={"0 auto"}>
            <NewH1
                marginTop={"100px"}>
                ADMIN HOME
            </NewH1>
        </FlexBox>
    )
}
export default NewHomeFragment;