import {ProductStatus} from "../model/ProductStatus";
import {Colors} from "../styledcomponents/Styles";
import {CompanyStatus} from "../model/CompanyStatus";

export class ColorUtils {
    static getColorByProductStatus(itemStatus: string): string {
        switch (itemStatus) {
            case ProductStatus.TEMP_DRAFT:
                return Colors.GRAY_5
            case ProductStatus.PRODUCT_DISPLAY:
                return Colors.MAIN
            case ProductStatus.APPROVAL_PENDING:
                return Colors.SECONDARY_09
            case ProductStatus.APPROVAL_REJECTED:
                return Colors.SECONDARY_02
            case ProductStatus.PUBLICATION_SUSPENDED:
                return Colors.GRAY_7
            case ProductStatus.APPROVAL_APPROVED:
                return Colors.SECONDARY_01
            case ProductStatus.DELETED:
                return Colors.GRAY_6
            default:
                return Colors.GRAY_4
        }
    }

    static getColorByCompanyStatus(status: string): string {
        switch (status) {
            case CompanyStatus.TEMP_DRAFT:
                return Colors.GRAY_5
            case CompanyStatus.APPROVAL_PENDING:
                return Colors.SECONDARY_09
            case CompanyStatus.APPROVAL_REJECTED:
                return Colors.SECONDARY_02
            case CompanyStatus.APPROVAL_APPROVED:
                return Colors.SECONDARY_01
            default:
                return Colors.GRAY_4
        }
    }
}