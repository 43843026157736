/**
 * @author jaeho.lee104 on 2023. 11. 25..
 */
import {CommonProps} from "../styledcomponents/CommonComponent";
import React, {ChangeEvent, FC, useCallback, useEffect, useRef, useState} from "react";
import {Colors} from "../styledcomponents/Styles";
import FlexBox from "../styledcomponents/FlexBox";
import NewH5 from "../styledcomponents/text/NewH5";
import Image from "../styledcomponents/Image";
import NewP2 from "../styledcomponents/text/NewP2";
import NewButton from "../styledcomponents/NewButton";
import {FileUtils} from "../utils/FileUtils";
import Text from "../styledcomponents/Text";


export interface FilePickerBoxProps extends CommonProps {
    label?: string;
    labelGap?: string;
    labelFontSize?: string;
    labelColor?: string;
    labelFontWight?: number | "normal";
    labelVisible?: boolean;
    includeExtensions?: Array<string>
    onChange: (selectedFile: File | null) => void;
}


const FilePickerBox: FC<FilePickerBoxProps> = ({
                                                   labelVisible = false,
                                                   label = "",
                                                   labelGap = "20px",
                                                   labelFontSize = "16px",
                                                   labelColor = Colors.GRAY_7,
                                                   labelFontWight = 500,
                                                   onChange = (file) => {
                                                   },
                                                   includeExtensions = undefined,
                                                   ...props
                                               }) => {

    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [file, setFile] = useState<File | null>(null);
    const dragRef = useRef<HTMLLabelElement | null>(null);
    const onChangeFiles = (e: ChangeEvent<HTMLInputElement> | any): void => {
        let selectFile: File | null;
        if (e.type === "drop") {
            selectFile = e.dataTransfer && e.dataTransfer.files ? e.dataTransfer.files[0] : null;
        } else {
            selectFile = e.target.files ? e.target.files[0] : null;
            e.target.value = null
        }
        if (selectFile) {
            if (includeExtensions) {
                let fileName = selectFile.name
                let allowed = false
                for (let i = 0; i < includeExtensions.length; i++) {
                    let targetExtension = includeExtensions[i]
                    let targetExtensionLength = targetExtension.length
                    if (fileName.length < targetExtensionLength + 2) {
                        continue
                    }
                    let extension = fileName.substring(fileName.length - targetExtensionLength)
                    if (extension !== targetExtension) {
                        continue
                    }
                    allowed = true
                }
                if (!allowed) {
                    alert(`You must upload as one of these file types. [${includeExtensions}]`)
                    return
                }
            }
            setFile(selectFile)
        }
    }
    const handleDragIn = useCallback((e: DragEvent): void => {
        e.preventDefault();
        e.stopPropagation();
    }, []);

    const handleDragOut = useCallback((e: DragEvent): void => {
        e.preventDefault();
        e.stopPropagation();

        setIsDragging(false);
    }, []);

    const handleDragOver = useCallback((e: DragEvent): void => {
        e.preventDefault();
        e.stopPropagation();

        if (e.dataTransfer!.files) {
            setIsDragging(true);
        }
    }, []);

    const handleDrop = useCallback(
        (e: DragEvent): void => {
            e.preventDefault();
            e.stopPropagation();

            onChangeFiles(e);
            setIsDragging(false);
        },
        []
    );

    const initDragEvents = useCallback((): void => {
        if (dragRef.current !== null) {
            dragRef.current.addEventListener("dragenter", handleDragIn);
            dragRef.current.addEventListener("dragleave", handleDragOut);
            dragRef.current.addEventListener("dragover", handleDragOver);
            dragRef.current.addEventListener("drop", handleDrop);
        }
    }, [handleDragIn, handleDragOut, handleDragOver, handleDrop]);

    const resetDragEvents = useCallback((): void => {
        if (dragRef.current !== null) {
            dragRef.current.removeEventListener("dragenter", handleDragIn);
            dragRef.current.removeEventListener("dragleave", handleDragOut);
            dragRef.current.removeEventListener("dragover", handleDragOver);
            dragRef.current.removeEventListener("drop", handleDrop);
        }
    }, [handleDragIn, handleDragOut, handleDragOver, handleDrop]);

    useEffect(() => {
        onChange(file)
    }, [file])

    useEffect(() => {
        initDragEvents();

        return () => resetDragEvents();
    }, [initDragEvents, resetDragEvents]);

    return <FlexBox
        flexDirection={"column"}
        {...props}>
        <Text
            visible={labelVisible}
            fontSize={labelFontSize}
            fontWeight={labelFontWight}
            color={labelColor}>{label}</Text>
        <label
            ref={dragRef}
            htmlFor={"fileUpload"}>
            <FlexBox
                id={'file-upload-container'}
                height={"230px"}
                padding={"40px 0px"}
                flexDirection={"column"}
                marginTop={labelGap}
                justifyContent={"center"}
                border={`2px dashed ${Colors.GRAY_4}`}
                backgroundColor={isDragging ? Colors.GRAY_3 : Colors.GRAY_1}
                borderRadius={"12px"}
                alignItems={"center"}>
                <Image
                    width={"48px"}
                    height={"48px"}
                    src={require(`assets/images/ic-photo-48.svg`).default}
                    alt="ic-photo"/>
                <NewP2
                    textAlign={"center"}
                    color={Colors.GRAY_7}
                    marginTop={"8px"}>
                    {"파일을 드래그하여 이곳에 올려주시거나\n아래의 버튼을 클릭하여 파일을 추가해주세요."}
                </NewP2>
                <input
                    type="file"
                    id="fileUpload"
                    style={{display: "none"}}
                    multiple={true}
                    onChange={onChangeFiles}
                />
                <NewButton
                    marginTop={"8px"}
                    padding={'10px 16px'}
                    width={"fit-content"}
                    height={"fit-content"}>
                    {'업로드하기'}
                </NewButton>
            </FlexBox>
        </label>
        {file &&
            <FlexBox
                marginTop={"20px"}
                borderRadius={"4px"}
                padding={"8px 16px"}
                justifyContent={"space-between"}
                alignItems={"center"}
                border={`1px solid ${Colors.GRAY_4}`}>
                <NewH5
                    textOverflow={"ellipsis"}
                    whiteSpace={"nowrap"}
                    overflow={"hidden"}
                    color={Colors.GRAY_7}>
                    {file.name}
                </NewH5>
                <FlexBox
                    alignItems={"center"}>
                    <NewH5
                        color={Colors.GRAY_7}>
                        {`(${FileUtils.convertBytesToMegaBytes(file.size)}MB)`}
                    </NewH5>
                    <Image
                        cursor={"pointer"}
                        marginLeft={"8px"}
                        onClick={() => {
                            setFile(null)
                        }}
                        width={"20px"}
                        height={"20px"}
                        src={require("assets/images/ic-close-20.svg").default}
                        alt="ic-remove"/>
                </FlexBox>
            </FlexBox>}
    </FlexBox>
}

export default FilePickerBox