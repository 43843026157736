/**
 * @author jaeho.lee104 on 2023. 04. 06..
 */
import React, {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import FlexBox from "../styledcomponents/FlexBox";
import {Colors, Sizes} from "../styledcomponents/Styles";
import NewH4 from "../styledcomponents/text/NewH4";
import VerticalDivider from "../styledcomponents/VerticalDivider";
import CategoryManagerFragment from "./CategoryManageFragment";
import CertificationManageFragment from "./CertificationManageFragment";
import ProductListManageFragment from "./ProductListManageFragment";

const ProductManageFragment = () => {

    const [searchParams, setSearchParams] = useSearchParams()
    const [selectedMenuIndex, setSelectedMenuIndex] = useState(Number(searchParams.get('index') || '0'))
    const navigate = useNavigate()

    useEffect(() => {
        setSelectedMenuIndex(Number(searchParams.get('index') || String(selectedMenuIndex)))
    }, [searchParams])

    function getPageByIndex(): React.ReactNode {
        if (selectedMenuIndex === 1) {
            return <CategoryManagerFragment/>
        } else if (selectedMenuIndex === 2) {
            return <CertificationManageFragment/>
        } else {
            return <ProductListManageFragment/>
        }
    }

    return (
        <FlexBox
            width={"100%"}
            minWidth={Sizes.CONTENT_WIDTH}
            height={"100%"}>
            <FlexBox
                flexDirection={"column"}
                minWidth={"240px"}
                borderRight={`2px solid ${Colors.GRAY_2}`}
                backgroundColor={Colors.WHITE}
                padding={"48px 40px"}>
                <FlexBox
                    flexDirection={"column"}>
                    <FlexBox
                        marginTop={"24px"}>
                        <VerticalDivider
                            backgroundColor={Colors.GRAY_3}
                            height={"100%"}
                            width={"4px"}/>
                        <FlexBox
                            flexDirection={"column"}>
                            <NewH4
                                color={selectedMenuIndex === 0 ? Colors.GRAY_8 : Colors.GRAY_4}
                                cursor={"pointer"}
                                onClick={() => {
                                    navigate("/products?index=0")
                                }}
                                marginLeft={"16px"}>
                                상품 목록 관리
                            </NewH4>
                            <NewH4
                                color={selectedMenuIndex === 1 ? Colors.GRAY_8 : Colors.GRAY_4}
                                cursor={"pointer"}
                                marginTop={"16px"}
                                onClick={() => {
                                    navigate("/products?index=1")
                                }}
                                marginLeft={"16px"}>
                                카테고리 관리
                            </NewH4>
                            <NewH4
                                color={selectedMenuIndex === 2 ? Colors.GRAY_8 : Colors.GRAY_4}
                                cursor={"pointer"}
                                marginTop={"16px"}
                                onClick={() => {
                                    navigate("/products?index=2")
                                }}
                                marginLeft={"16px"}>
                                인증서 관리
                            </NewH4>
                        </FlexBox>
                    </FlexBox>
                </FlexBox>
            </FlexBox>
            <FlexBox
                flexGrow={1}>
                {getPageByIndex()}
            </FlexBox>
        </FlexBox>
    )
}
export default ProductManageFragment;