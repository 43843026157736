import React from "react";
import {Route, Routes} from "react-router-dom";
import "App.css";
import "Common.css";
import PasswordResetFragment from "./fragments/PasswordResetFragment";
import NotFoundFragment from "./fragments/NotFoundFragment";
import PcAppBar from "./components/PcAppBar";
import "bootstrap/dist/css/bootstrap.css";
import NewHomeFragment from "./fragments/NewHomeFragment";
import NewLoginFragment from "./fragments/NewLoginFragment";
import UserManagerFragment from "./fragments/UserManageFragment";
import ProductManageFragment from "./fragments/ProductManageFragment";
import ProductDetailManageFragment from "./fragments/ProductDetailManageFragment";
import PDFViewerFragment from "./fragments/PDFViewerFragment";
import CompanyManageFragment from "./fragments/CompanyManageFragment";
import CompanyDetailInfoFragment from "./fragments/CompanyDetailInfoFragment";
import ToolsFragment from "./fragments/ToolsFragment";

const App = () => {

    return (
        <div id="app-root" className="App">
            <PcAppBar/>
            <div className="App-content">
                <Routes>
                    <Route path="/viewer/pdf" element={<PDFViewerFragment/>}/>
                    <Route path="/password_reset/:key" element={<PasswordResetFragment/>}/>
                    <Route path="/login" element={<NewLoginFragment/>}/>
                    <Route path="/users" element={<UserManagerFragment/>}/>
                    <Route path="/companies" element={<CompanyManageFragment/>}/>
                    <Route path="/tools" element={<ToolsFragment/>}/>
                    <Route path="/company-detail" element={<CompanyDetailInfoFragment/>}/>
                    <Route path="/products" element={<ProductManageFragment/>}/>
                    <Route path="/product-detail" element={<ProductDetailManageFragment/>}/>
                    <Route path="/" element={<NewHomeFragment/>}/>
                    <Route path="*" element={<NotFoundFragment/>}/>
                </Routes>
            </div>
        </div>
    );
};

export default App;
