import React, {useEffect, useState} from "react";
import FlexBox from "../styledcomponents/FlexBox";
import NewH1 from "../styledcomponents/text/NewH1";
import {ImportApplyNoticeListItem} from "../model/ImportApplyNoticeListItem";
import {AdminAPI} from "../network/AdminAPI";
import {ResponseCodes} from "../network/ResponseCodes";
import {ColumnsType} from "antd/es/table";
import Text from "../styledcomponents/Text";
import {Table, TablePaginationConfig} from "antd";
import NewButton from "../styledcomponents/NewButton";
import ImportApplyNoticeDialog from "../dialog/ImportApplyNoticeDialog";
import "./ImportNoticeManagerFragment.css"

const ImportNoticeManageFragment = () => {


    const [notices, setNotices] = useState<Array<ImportApplyNoticeListItem>>([])
    const [selectedNoticeId, setSelectedNoticeSequence] = useState<number>(-1)
    const [isNoticeDialogOpen, setIsNoticeDialogOpen] = useState(false)
    const [refreshKey, setRefreshKey] = useState(0)

    const tableColumns: ColumnsType<ImportApplyNoticeListItem> = [
        {
            title: 'No',
            dataIndex: "idx",
            align: "center",
            width: "60px",
            render: (value, record, index) => {
                return <FlexBox width={"100%"}
                                height={"100%"}
                                alignItems={"center"}
                                justifyContent={"center"}>
                    <Text
                        marginLeft={"8px"}
                        fontWeight={700}>
                        {record.idx}
                    </Text>
                </FlexBox>
            },
        },
        {
            title: 'Subject',
            dataIndex: "subject",
            align: "center",
            width: "320px",
            ellipsis: true,
            render: (value, record, index) => {
                return <FlexBox width={"100%"}
                                height={"100%"}
                                alignItems={"center"}>
                    <FlexBox
                        alignItems={"center"}>
                        <Text
                            marginLeft={"8px"}
                            fontWeight={700}>
                            {record.subject}
                        </Text>
                    </FlexBox>
                </FlexBox>
            },
        },
        {
            title: 'createDate',
            dataIndex: "createDate",
            align: "center",
            width: "120px",
            ellipsis: true,
            render: (value, record, index) => {
                return <FlexBox width={"100%"}
                                height={"100%"}
                                alignItems={"center"}
                                justifyContent={"center"}>
                    <Text
                        fontWeight={400}>
                        {record.createDate}
                    </Text>
                </FlexBox>
            },
        }
    ];

    useEffect(() => {
        if (!isNoticeDialogOpen) {
            setSelectedNoticeSequence(-1)
        }
    }, [isNoticeDialogOpen]);

    useEffect(() => {
        AdminAPI.getImportApplyNotices()
            .then((response) => {
                const responseCode = ResponseCodes.of(response.data.code)
                if (responseCode.isSuccess()) {
                    setNotices(response.data.data || [])
                } else {
                    alert("error: " + responseCode)
                }
            })
    }, [refreshKey])

    function handleNoticeClicked(notice: ImportApplyNoticeListItem) {
        setSelectedNoticeSequence(notice.noticeSequence)
        setIsNoticeDialogOpen(true)
    }

    return <FlexBox
        padding={"40px"}
        width={"1200px"}
        margin={"0 auto"}
        flexDirection={"column"}
        id={'table-container'}
        overflow={"auto"}>
        <FlexBox
            alignItems={"center"}
            marginTop={"40px"}
            justifyContent={"space-between"}>
            <NewH1>
                공지 관리
            </NewH1>
            <NewButton
                width={"120px"}
                colorType={0}
                onClick={() => {
                    setIsNoticeDialogOpen(true)
                }}>
                공지 등록
            </NewButton>
        </FlexBox>
        <Table
            style={{
                marginTop: "40px",
                width: "1120px",
            }}
            columns={tableColumns}
            dataSource={notices}
            pagination={{
                position: ["bottomCenter"],
                pageSize: 10,
                showSizeChanger: false,
            } as TablePaginationConfig}
            onRow={(record, rowIndex) => {
                return {
                    onClick: (event) => {
                        handleNoticeClicked(record);
                    },
                };
            }}
        />
        <ImportApplyNoticeDialog dialogProps={{
            noticeSequence: selectedNoticeId,
            isOpen: isNoticeDialogOpen,
            onClose: (positive) => {
                setIsNoticeDialogOpen(false)
                if (positive) {
                    setRefreshKey(refreshKey + 1)
                }
            }
        }}/>
    </FlexBox>
}
export default ImportNoticeManageFragment;