import React, {useState} from 'react';
import {Document, Page, pdfjs} from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import {useSearchParams} from "react-router-dom";
import FlexBox from "../styledcomponents/FlexBox";
import NewButton from "../styledcomponents/NewButton";
import NewP2 from "../styledcomponents/text/NewP2";

// PDF.js의 워커 경로 설정
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFViewerFragment = () => {

    const [numPages, setNumPages] = useState(1)
    const [pageNumber, setPageNumber] = useState(1)
    const [searchParams, setSearchParams] = useSearchParams()
    const url = searchParams.get('url')

    return <FlexBox
        width={"100%"}
        flexDirection={"column"}
        alignItems={"center"}>
        <FlexBox
            alignItems={"center"}
            flexDirection={"column"}
            minWidth={"1200px"}>
            <Document
                file={url}
                onLoadSuccess={({numPages}) => {
                    setNumPages(numPages)
                }}>
                <FlexBox
                    alignItems={"center"}
                    flexDirection={"column"}
                    marginTop={"20px"}>
                    <NewP2>
                        현재 페이지: ({pageNumber}/{numPages})
                    </NewP2>
                    <FlexBox
                        width={"200px"}
                        marginTop={"8px"}
                        marginBottom={"20px"}>
                        <NewButton
                            onClick={() => {
                                if (pageNumber > 1) {
                                    setPageNumber(pageNumber - 1)
                                }
                            }}>
                            이전
                        </NewButton>
                        <NewButton
                            marginLeft={"20px"}
                            onClick={() => {
                                if (pageNumber < numPages) {
                                    setPageNumber(pageNumber + 1)
                                }
                            }}>
                            다음
                        </NewButton>
                    </FlexBox>
                </FlexBox>
                <Page pageNumber={pageNumber} height={1000}/>
            </Document>
        </FlexBox>
    </FlexBox>
}

export default PDFViewerFragment;
