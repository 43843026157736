/**
 * @author jaeho.lee104 on 2023. 08. 08..
 */
import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import FlexBox from "../styledcomponents/FlexBox";
import {Colors} from "../styledcomponents/Styles";
import NewH2 from "../styledcomponents/text/NewH2";
import {ReactiveContentContainer, RootContainer} from "../styledcomponents/Containers";
import NewP1 from "../styledcomponents/text/NewP1";
import Input from "../styledcomponents/Input";
import Image from "../styledcomponents/Image";
import useTablet from "../uses/UseTablet";
import useMobile from "../uses/UseMobile";
import styled from "styled-components";
import Text from "../styledcomponents/Text";
import NewButton from "../styledcomponents/NewButton";
import {RootState} from "../modules";
import {Admins} from "../modules/Admins";


const lockClosePath = require(`assets/images/ic-lock-close.svg`).default
const lockOpenPath = require(`assets/images/ic-lock-open.svg`).default

const InputErrorText = styled(Text)`
  margin-top: 6px;
  font-size: 12px;
  text-align: left;
  color: ${Colors.INPUT_ERROR};
`

const NewLoginFragment = () => {

    const componentName = "NewLoginFragment"
    const {signedIn} = useSelector((state: RootState) => state.adminReducer, shallowEqual);
    const [password, setPassword] = useState("")
    const [lockClose, setLockClose] = useState(false)
    const [passwordError, setPasswordError] = useState(false)
    const passwordRef = useRef<HTMLInputElement>(null)

    const [email, setEmail] = useState("")
    const [emailError, setEmailError] = useState(false)
    const emailRef = useRef<HTMLInputElement>(null)

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const isTablet = useTablet()
    const isMobile = useMobile()

    useEffect(() => {
        if (signedIn) {
            navigate("/")
        }
    }, [signedIn])

    function handleLoginButtonClicked() {
        dispatch<any>(Admins.adminLogin(email, password))
    }

    return (<RootContainer
        backgroundColor={Colors.GRAY_0}
        minHeight={"100%"}>
        <ReactiveContentContainer
            flexDirection={isTablet ? "column" : "row"}
            paddingTop={"80px"}
            paddingBottom={"80px"}
            alignItems={"center"}
            paddingLeft={isMobile ? "20px" : "0px"}
            paddingRight={isMobile ? "20px" : "0px"}
            justifyContent={"space-around"}>
            <FlexBox
                marginTop={isTablet ? "40px" : "0px"}
                backgroundColor={Colors.WHITE}
                maxWidth={"420px"}
                width={isMobile ? "100%" : "420px"}
                paddingTop={"24px"}
                paddingBottom={"24px"}
                paddingLeft={"32px"}
                paddingRight={"32px"}
                border={`1px solid ${Colors.GRAY_2}`}
                borderRadius={"24px"}
                boxShadow={"0px 2px 40px 0px rgba(0, 0, 0, 0.05);"}
                flexDirection={"column"}
                onChange={(e) => {
                    if (emailError) {
                        setEmailError(false)
                    }
                    if (passwordError) {
                        setPasswordError(false)
                    }
                }}>
                <NewH2
                    marginTop={"12px"}
                    color={Colors.GRAY_8}>{t('do_login')}</NewH2>
                <NewP1
                    color={Colors.GRAY_7}
                    marginTop={"24px"}>관리자 아이디</NewP1>
                <FlexBox
                    width={"100%"}
                    alignSelf={"center"}
                    marginTop={"8px"}>
                    <Input
                        paddingRight={"48px"}
                        width={"100%"}
                        fontWeight={700}
                        lineHeight={"150%"}
                        ref={emailRef}
                        error={emailError}
                        value={email}
                        type="text"
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                handleLoginButtonClicked()
                            }
                        }}
                        onChange={(e) => {
                            setEmail(e.target.value)
                        }}
                    />
                    {email && <Image
                        width={"24px"}
                        height={"24px"}
                        position={"absolute"}
                        right={"16px"}
                        alignSelf={"center"}
                        src={require("assets/images/ic-close-circle.svg").default}
                        cursor={"pointer"}
                        onClick={() => {
                            setEmail("")
                        }}
                        alt="email-clear-button"/>}
                </FlexBox>
                {emailError && <InputErrorText>{`아이디를 입력해주세요.`}</InputErrorText>}
                <NewP1
                    color={Colors.GRAY_7}
                    marginTop={"20px"}>{t('password')}</NewP1>
                <FlexBox
                    width={"100%"}
                    alignSelf={"center"}
                    marginTop={"8px"}>
                    <Input
                        paddingRight={"48px"}
                        width={"100%"}
                        fontWeight={700}
                        lineHeight={"150%"}
                        ref={passwordRef}
                        error={passwordError}
                        value={password}
                        type={lockClose ? "text" : "password"}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                handleLoginButtonClicked()
                            }
                        }}
                        onChange={(e) => {
                            setPassword(e.target.value)
                        }}
                    />
                    <Image
                        maxWidth={"24px"}
                        width={"24px"}
                        maxHeight={"24px"}
                        height={"24px"}
                        position={"absolute"}
                        right={"16px"}
                        alignSelf={"center"}
                        cursor={"pointer"}
                        src={lockClose ? lockOpenPath : lockClosePath}
                        onClick={() => {
                            setLockClose(!lockClose)
                        }}
                        alt="password-lock-icon"/>

                </FlexBox>
                {passwordError && <InputErrorText>{t('invalid_password_format')}</InputErrorText>}
                <NewButton
                    borderRadius={"12px"}
                    colorType={0}
                    marginTop={"48px"}
                    fontSize={"16px"}
                    width={"100%"}
                    height={"52px"}
                    marginBottom={"24px"}
                    onClick={() => handleLoginButtonClicked()}>
                    {t('login')}
                </NewButton>
            </FlexBox>
        </ReactiveContentContainer>
    </RootContainer>)
}
export default NewLoginFragment;