/**
 * @author jaeho.lee104 on 2023. 11. 09..
 */
import {createTheme, ThemeProvider} from "@mui/material/styles";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Dialog} from "@mui/material";
import FlexBox from "../styledcomponents/FlexBox";
import NewH3 from "../styledcomponents/text/NewH3";
import Image from "../styledcomponents/Image";
import NewP2 from "../styledcomponents/text/NewP2";
import NewButton from "../styledcomponents/NewButton";
import {AdminCompanyModel} from "../model/AdminCompanyModel";
import {Colors} from "../styledcomponents/Styles";
import TextArea from "antd/lib/input/TextArea";

const theme = createTheme({
    components: {
        MuiDialog: {
            styleOverrides: {
                paper: {
                    minWidth: "480px",
                    borderRadius: "16px"
                }
            }
        }
    },
    palette: {
        background: {
            paper: '#fff',
        },
        text: {
            primary: '#000',
            secondary: '#46505A',
        },
        action: {
            active: '#001E3C',
        },
    },
});

export interface UserRejectDialogProps {
    isOpen: boolean
    adminCompanyModel: AdminCompanyModel
    onClose: (positive: boolean, reason: string) => void
}

const UserRejectDialog: React.FC<{
    dialogProps: UserRejectDialogProps
}> = ({dialogProps}) => {

    const [isOpen, setIsOpen] = useState(false);
    const [reason, setReason] = useState("")
    const {t} = useTranslation()

    useEffect(() => {
        setIsOpen(dialogProps.isOpen);
    }, [dialogProps.isOpen]);

    const handleOk = () => {
        if (!isOpen) {
            return;
        }
        dialogProps.onClose(true, reason);
    };

    const handleCancel = () => {
        if (!isOpen) {
            return;
        }
        dialogProps.onClose(false, reason);
    };

    return <>
        <ThemeProvider theme={theme}>
            <Dialog
                open={isOpen}
                onClose={handleCancel}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        handleOk();
                    }
                }}>
                <FlexBox
                    flexDirection={"column"}
                    padding={"32px"}>
                    <FlexBox
                        alignItems={"center"}
                        justifyContent={"space-between"}>
                        <NewH3>
                            {dialogProps.adminCompanyModel.companyNm}
                        </NewH3>
                        <Image
                            cursor={"pointer"}
                            onClick={handleCancel}
                            width={"20px"}
                            height={"20px"}
                            src={require("assets/images/ic-close-20.svg").default}
                            alt="ic-close"/>
                    </FlexBox>
                    <NewP2
                        marginTop={"16px"}>
                        {dialogProps.adminCompanyModel.userId}
                    </NewP2>
                    <NewP2
                        marginTop={"24px"}
                        color={Colors.GRAY_7}>
                        거절 사유
                    </NewP2>
                    <TextArea
                        value={reason}
                        onChange={(e) => {
                            setReason(e.target.value)
                        }}
                        showCount
                        maxLength={400}
                        style={{
                            height: 190,
                            marginTop: "8px",
                            resize: 'none',
                            padding: "8px",
                        }}
                    />
                    <FlexBox
                        height={"52px"}
                        alignItems={"center"}
                        marginTop={"32px"}>
                        <NewButton
                            height={"52px"}
                            colorType={1}
                            width={"100%"}
                            fontSize={"16px"}
                            fontWeight={500}
                            borderRadius={"12px"}
                            onClick={handleCancel}>
                            취소
                        </NewButton>
                        <NewButton
                            height={"52px"}
                            width={"100%"}
                            marginLeft={"16px"}
                            colorType={3}
                            fontSize={"16px"}
                            fontWeight={700}
                            onClick={handleOk}
                            borderRadius={"12px"}>
                            {'거절'}
                        </NewButton>
                    </FlexBox>
                </FlexBox>
            </Dialog>
        </ThemeProvider>
    </>
}

export default UserRejectDialog