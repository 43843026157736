/**
 * @author jaeho.lee104 on 2023. 11. 09..
 */
import {createTheme, ThemeProvider} from "@mui/material/styles";
import React, {useEffect, useState} from "react";
import {CircularProgress, Dialog} from "@mui/material";
import FlexBox from "../styledcomponents/FlexBox";
import NewH3 from "../styledcomponents/text/NewH3";
import Image from "../styledcomponents/Image";
import NewP2 from "../styledcomponents/text/NewP2";
import NewButton from "../styledcomponents/NewButton";
import {AdminCompanyModel} from "../model/AdminCompanyModel";
import {Colors} from "../styledcomponents/Styles";
import {AdminOsmfgCompanyModel} from "../model/AdminOsmfgCompanyModel";
import {AdminAPI} from "../network/AdminAPI";
import {NewRadioItem} from "../components/NewRadioItem";

const theme = createTheme({
    components: {
        MuiDialog: {
            styleOverrides: {
                paper: {
                    minWidth: "480px",
                    borderRadius: "16px"
                }
            }
        }
    },
    palette: {
        background: {
            paper: '#fff',
        },
        text: {
            primary: '#000',
            secondary: '#46505A',
        },
        action: {
            active: '#001E3C',
        },
    },
});

export interface UserCompanyCheckDialogProps {
    isOpen: boolean
    adminCompanyModel: AdminCompanyModel
    onClose: (positive: boolean, selectedOsmfgcoId: string | null) => void
}

const UserCompanyCheckDialog: React.FC<{
    dialogProps: UserCompanyCheckDialogProps
}> = ({dialogProps}) => {

    const [isOpen, setIsOpen] = useState(false);
    const [osmfgCompanies, setOsmfgCompanies] = useState<Array<AdminOsmfgCompanyModel> | null>(null)
    const [checkedIndex, setCheckedIndex] = useState(-1)

    useEffect(() => {
        setIsOpen(dialogProps.isOpen);
    }, [dialogProps.isOpen]);

    useEffect(() => {
        AdminAPI.getRelationCompanies(dialogProps.adminCompanyModel.companyNm)
            .then((response) => {
                setOsmfgCompanies(response.data.data || [])
            })
    }, [dialogProps.adminCompanyModel.companyId])

    const handleOk = () => {
        if (!isOpen) {
            return;
        }
        dialogProps.onClose(true,
            checkedIndex === -1 || !osmfgCompanies ? null : osmfgCompanies[checkedIndex].osmfgcoId);
    };

    const handleCancel = () => {
        if (!isOpen) {
            return;
        }
        dialogProps.onClose(false, null);
    };

    return <>
        <ThemeProvider theme={theme}>
            <Dialog
                open={isOpen}
                onClose={handleCancel}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        handleOk();
                    }
                }}>
                <FlexBox
                    flexDirection={"column"}
                    padding={"32px"}>
                    <FlexBox
                        alignItems={"center"}
                        justifyContent={"space-between"}>
                        <NewH3>
                            {`[기업회원심사] ${dialogProps.adminCompanyModel.companyNm}`}
                        </NewH3>
                        <Image
                            cursor={"pointer"}
                            onClick={handleCancel}
                            width={"20px"}
                            height={"20px"}
                            src={require("assets/images/ic-close-20.svg").default}
                            alt="ic-close"/>
                    </FlexBox>
                    <NewP2
                        marginTop={"16px"}>
                        {dialogProps.adminCompanyModel.userId}
                    </NewP2>
                    <NewH3
                        marginTop={"24px"}
                        color={Colors.GRAY_7}>
                        기존 제조회사 목록
                    </NewH3>
                    <NewP2
                        marginTop={"8px"}>
                        ( 선택시 제조회사 정보와 현재등록되는 회사와 데이터 매칭됨 )
                    </NewP2>
                    <FlexBox
                        flexDirection={"column"}
                        marginTop={"20px"}
                        maxHeight={"400px"}
                        overflow={"auto"}>
                        {osmfgCompanies && osmfgCompanies.length > 0 && osmfgCompanies.map((item, index) => {
                            return <NewRadioItem
                                marginTop={"10px"}
                                height={"fit-content"}
                                title={`${item.country} ${item.osmfgcoNm}`}
                                checked={
                                    index === checkedIndex
                                }
                                onClick={() => {
                                    setCheckedIndex(index)
                                }}
                            />
                        })}
                        {osmfgCompanies && osmfgCompanies.length === 0 && <NewP2>기존 등록된 제조회사 없음</NewP2>}
                        {!osmfgCompanies && <FlexBox
                            width={"100px"}
                            height={"100px"}
                            alignSelf={"center"}
                            alignItems={"center"}
                            justifyContent={"center"}>
                            <CircularProgress
                                sx={{
                                    color: Colors.BLUE_20
                                }}/>
                        </FlexBox>}
                    </FlexBox>
                    <FlexBox
                        height={"52px"}
                        alignItems={"center"}
                        marginTop={"32px"}>
                        <NewButton
                            height={"52px"}
                            colorType={1}
                            width={"100%"}
                            fontSize={"16px"}
                            fontWeight={500}
                            borderRadius={"12px"}
                            onClick={handleCancel}>
                            취소
                        </NewButton>
                        <NewButton
                            height={"52px"}
                            width={"100%"}
                            marginLeft={"16px"}
                            colorType={0}
                            fontSize={"16px"}
                            fontWeight={700}
                            onClick={handleOk}
                            borderRadius={"12px"}>
                            {'승인'}
                        </NewButton>
                    </FlexBox>
                </FlexBox>
            </Dialog>
        </ThemeProvider>
    </>
}

export default UserCompanyCheckDialog