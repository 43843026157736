/**
 * @author jaeho.lee104 on 2023. 04. 06..
 */
import React, {useEffect, useState} from "react";
import FlexBox from "../styledcomponents/FlexBox";
import {useNavigate} from "react-router-dom";
import NewH1 from "../styledcomponents/text/NewH1";
import {AdminAPI} from "../network/AdminAPI";
import {AdminCompanyModel} from "../model/AdminCompanyModel";
import {Colors} from "../styledcomponents/Styles";
import NewH3 from "../styledcomponents/text/NewH3";
import NewH6 from "../styledcomponents/text/NewH6";
import NewButton from "../styledcomponents/NewButton";
import UserRejectDialog from "../dialog/UserRejectDialog";
import {ResponseCodes} from "../network/ResponseCodes";
import UserCompanyCheckDialog from "../dialog/UserCompanyCheckDialog";
import {NewRadioItem} from "../components/NewRadioItem";
import NewH4 from "../styledcomponents/text/NewH4";

const SolutionUserManagerFragment = () => {

    const navigate = useNavigate()
    const [companies, setCompanies] = useState<Array<AdminCompanyModel>>([])
    const [refresh, setRefresh] = useState(0)
    const [isRejectDialogOpen, setRejectDialogOpen] = useState(false)
    const [isCompanyCheckDialogOpen, setCompanyCheckDialogOpen] = useState(false)
    const [checkCompanyModel, setCheckCompanyModel] = useState<AdminCompanyModel | null>(null)
    const [userStateFilter, setUserStateFilter] = useState("ALL")

    useEffect(() => {
        AdminAPI.getCompanies(userStateFilter)
            .then((res) => {
                let data = res.data.data
                setCompanies(data || [])
            })
    }, [refresh, userStateFilter])

    function handleApproveButtonClicked(company: AdminCompanyModel, selectedOsmfgcoId: string | null) {
        AdminAPI.verifyCompany(company.companyId, selectedOsmfgcoId, true, null)
            .then((response) => {
                const responseCode = ResponseCodes.of(response.data.code);
                if (responseCode.isSuccess()) {
                    setRefresh(refresh + 1)
                } else {
                    alert(`[failed] code: ${response.data.code}, message: ${response.data.message}`)
                }
            })
    }

    function handleRejectButtonClicked(company: AdminCompanyModel, reason: string) {
        AdminAPI.verifyCompany(company.companyId, null, false, reason)
            .then((response) => {
                const responseCode = ResponseCodes.of(response.data.code);
                if (responseCode.isSuccess()) {
                    setRefresh(refresh + 1)
                } else {
                    alert(`[failed] code: ${response.data.code}, message: ${response.data.message}`)
                }
            })
    }

    function downloadBusinessNumberFile(company: AdminCompanyModel) {
        AdminAPI.getFile(company.licenseFileId)
            .then((response) => {
                console.log(response)
                const type = response.headers['content-type']
                // @ts-ignore
                const blob = new Blob([response.data], {type: type, encoding: 'UTF-8'})
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = `[심사용]_${company.companyNm}_사업자등록증.pdf`
                link.click()
            })
    }

    return (
        <FlexBox
            padding={"40px"}
            width={"1400px"}
            margin={"0 auto"}
            flexDirection={"column"}
            overflow={"auto"}
            alignItems={"flex-start"}>
            <NewH1
                marginTop={"40px"}>
                회원 관리 및 심사
            </NewH1>

            <FlexBox
                marginTop={"20px"}
                alignItems={"center"}>
                <NewH4
                    marginRight={'10px'}
                    minWidth={'40px'}>
                    필터:
                </NewH4>
                <NewRadioItem
                    minWidth={"140px"}
                    title={"전체"}
                    checked={userStateFilter === "ALL"}
                    onClick={() => {
                        setUserStateFilter("ALL")
                    }}/>
                <NewRadioItem
                    minWidth={"140px"}
                    marginLeft={"16px"}
                    title={"대기중"}
                    checked={userStateFilter === "0"}
                    onClick={() => {
                        setUserStateFilter("0")
                    }}/>
                <NewRadioItem
                    minWidth={"140px"}
                    marginLeft={"16px"}
                    title={"승인됨"}
                    checked={userStateFilter === "1"}
                    onClick={() => {
                        setUserStateFilter("1")
                    }}/>
                <NewRadioItem
                    minWidth={"140px"}
                    marginLeft={"16px"}
                    title={"거절됨"}
                    checked={userStateFilter === "2"}
                    onClick={() => {
                        setUserStateFilter("2")
                    }}/>
            </FlexBox>
            <FlexBox
                width={"1200px"}
                flexWrap={"wrap"}
                marginTop={"40px"}>
                {companies.map((item, index) => {
                    return <FlexBox
                        width={"360px"}
                        border={`1px solid ${Colors.GRAY_4}`}
                        borderRadius={"12px"}
                        margin={"10px"}
                        flexDirection={"column"}
                        padding={"40px"}>
                        <NewH3>
                            {`[${index + 1}] 회사명: ${item.companyNm}`}
                        </NewH3>
                        <NewH6
                            marginTop={"16px"}>
                            {`이메일: ${item.userId}`}
                        </NewH6>
                        <NewH6
                            marginTop={"16px"}>
                            {`회사주소: ${item.companyAddress}`}
                        </NewH6>
                        <NewH6
                            marginTop={"16px"}>
                            {`사업자타입: ${item.businessLicenseType}`}
                        </NewH6>
                        <NewH6
                            marginTop={"16px"}>
                            {`사업자등록번호: ${item.businessLicenseNum}`}
                        </NewH6>
                        <NewH6
                            marginTop={"16px"}>
                            {`주요거래국가: ${item.majorTradingCountries}`}
                        </NewH6>
                        <NewH6
                            marginTop={"16px"}>
                            {`수출입경험: ${item.impExpExperience}`}
                        </NewH6>
                        <NewH6
                            marginTop={"16px"}>
                            {`인증수출자번호: ${item.customsAuthNum}`}
                        </NewH6>
                        <NewH6
                            marginTop={"16px"}>
                            {`웹사이트: ${item.website}`}
                        </NewH6>
                        <NewH6
                            border={`1px solid ${Colors.GRAY_3}`}
                            borderRadius={"8px"}
                            padding={"20px"}
                            cursor={"pointer"}
                            marginTop={"16px"}
                            onClick={() => {
                                window.open(`https://apis.thirdworks.co.kr/common/file/company/${item.licenseFileId}`)
                                // downloadBusinessNumberFile(item)
                            }}>
                            {`사업자등록증 다운로드`}
                        </NewH6>
                        <FlexBox
                            marginTop={"20px"}>
                            <NewButton
                                onClick={() => {
                                    setCheckCompanyModel(item)
                                    setCompanyCheckDialogOpen(true)
                                }}>
                                승인
                            </NewButton>
                            <NewButton
                                colorType={3}
                                marginLeft={"20px"}
                                onClick={() => {
                                    setCheckCompanyModel(item)
                                    setRejectDialogOpen(true)
                                }}>
                                거절
                            </NewButton>
                        </FlexBox>
                    </FlexBox>
                })}
            </FlexBox>
            {checkCompanyModel && <UserRejectDialog
                dialogProps={{
                    isOpen: isRejectDialogOpen,
                    adminCompanyModel: checkCompanyModel,
                    onClose: (positive, reason) => {
                        setRejectDialogOpen(false)
                        if (positive) {
                            handleRejectButtonClicked(checkCompanyModel, reason)
                        }
                        setCheckCompanyModel(null)
                    }
                }}
            />}
            {checkCompanyModel && <UserCompanyCheckDialog
                dialogProps={{
                    isOpen: isCompanyCheckDialogOpen,
                    adminCompanyModel: checkCompanyModel,
                    onClose: (positive, selectedOsmfgcoId) => {
                        setCompanyCheckDialogOpen(false)
                        if (positive) {
                            handleApproveButtonClicked(checkCompanyModel, selectedOsmfgcoId)
                        }
                        setCheckCompanyModel(null)
                    }
                }}
            />}
        </FlexBox>
    )
}
export default SolutionUserManagerFragment;