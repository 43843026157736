/**
 * @author jaeho.lee104 on 2023. 11. 08..
 */
import {AxiosResponse} from "axios";
import {SignInRequestBody} from "../model/SignInRequestBody";
import {SignInResult} from "../model/SignInResult";
import {createDefaultAxiosInstance} from "./NetworkCommon";
import {AdminCompanyModel} from "../model/AdminCompanyModel";
import {FileModel} from "../model/FileModel";
import {AdminOsmfgCompanyModel} from "../model/AdminOsmfgCompanyModel";
import {CategoryModel} from "../model/CategoryModel";
import {CertificationModel} from "../model/CertificationModel";
import {AccountUser} from "../model/AccountUser";
import {ProductDetailItem} from "../model/ProductDetailItem";
import {ProductListItem} from "../model/ProductListItem";
import {CompanyListItem} from "../model/CompnayListItem";
import {CompanyDetailItem} from "../model/CompanyDetailItem";
import {HsCodeRawData} from "../model/HsCodeRawData";
import {ImportApplyNoticeListItem} from "../model/ImportApplyNoticeListItem";
import {ImportApplyNoticeDetail} from "../model/ImportApplyNoticeDetail";

const instance = createDefaultAxiosInstance()

export class AdminAPI {

    static getAllUsers(): Promise<AxiosResponse<BaseResponse<Array<AccountUser>>>> {
        return instance.get("/admin/user/customers")
    }

    static getSourcingDetail(itemId: string): Promise<AxiosResponse<BaseResponse<ProductDetailItem>>> {
        return instance.get(`/admin/item/${itemId}`)
    }

    static updateProductStatus(itemId: string, itemStatus: string): Promise<AxiosResponse<BaseResponse<null>>> {
        return instance.put(`/admin/item/status?itemId=${itemId}&itemStatus=${itemStatus}`)
    }

    static signIn(signInRequestBody: SignInRequestBody): Promise<AxiosResponse<BaseResponse<SignInResult>>> {
        return instance.post("/admin/user/signIn", signInRequestBody);
    }

    static getCompanies(filter: string = ""): Promise<AxiosResponse<BaseResponse<Array<AdminCompanyModel>>>> {
        return instance.get("/admin/companies", {
            params: {
                verified: filter
            }
        });
    }

    static getRelationCompanies(companyName: string): Promise<AxiosResponse<BaseResponse<Array<AdminOsmfgCompanyModel>>>> {
        return instance.get("/admin/companies/osmfgco", {
            params: {
                companyNm: companyName
            }
        });
    }

    static verifyCompany(companyId: string, osmfgcoId: string | null, verified: boolean, reason: string | null): Promise<AxiosResponse<BaseResponse<null>>> {
        return instance.put(`/admin/companies/${companyId}`, {
            osmfgcoId: osmfgcoId,
            verified: verified ? "1" : "2",
            verifiedReason: reason
        });
    }

    static getFile(fileId: string): Promise<AxiosResponse<BaseResponse<FileModel>>> {
        return instance.get(`/common/file/company/${fileId}`, {
            responseType: "blob",
        });
    }

    static getCategories(): Promise<AxiosResponse<BaseResponse<Array<CategoryModel>>>> {
        return instance.get('/admin/category')
    }

    static addCategory(categoryName: string, categoryNameEn: string): Promise<AxiosResponse<BaseResponse<null>>> {
        return instance.post(`/admin/category`, {
            categoryName: categoryName,
            categoryNameEn: categoryNameEn
        });
    }

    static modifyCategory(categoryId: string, categoryName: string, categoryNameEn: string): Promise<AxiosResponse<BaseResponse<null>>> {
        return instance.put(`/admin/category/${categoryId}`, {
            categoryName: categoryName,
            categoryNameEn: categoryNameEn
        });
    }

    static deleteCategory(categoryId: string): Promise<AxiosResponse<BaseResponse<null>>> {
        return instance.delete(`/admin/category/${categoryId}`);
    }

    static getCertifications(): Promise<AxiosResponse<BaseResponse<Array<CertificationModel>>>> {
        return instance.get('/admin/certification')
    }

    static addCertification(certificationName: string,
                            certificationDesc: string,
                            certificationNameEn: string,
                            certificationDescEn: string,
                            imageFile: File): Promise<AxiosResponse<BaseResponse<null>>> {
        let formData = new FormData()
        let info = {
            name: certificationName,
            desc: certificationDesc,
            nameEn: certificationNameEn,
            descEn: certificationDescEn
        }
        formData.append("CertificationImage", imageFile)
        formData.append("certificationInformation", new Blob([JSON.stringify(info)], {
            type: "application/json"
        }))
        return instance.post("/admin/certification", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
    }

    static deleteCertification(certificationId: string): Promise<AxiosResponse<BaseResponse<null>>> {
        return instance.delete(`/admin/certification/${certificationId}`);
    }

    static getProducts(): Promise<AxiosResponse<BaseResponse<Array<ProductListItem>>>> {
        return instance.get("/admin/item")
    }

    static getSolutionCompanies(): Promise<AxiosResponse<BaseResponse<Array<CompanyListItem>>>> {
        return instance.get("/admin/solution/company")
    }

    static getSolutionCompanyDetail(companyId: string): Promise<AxiosResponse<BaseResponse<CompanyDetailItem>>> {
        return instance.get(`/admin/solution/company/${companyId}`)
    }

    static updateCompanyStatus(companyId: string, statusCode: string): Promise<AxiosResponse<BaseResponse<null>>> {
        return instance.put(`/admin/solution/company/status`, {
            companyDetailId: companyId,
            statusCode: statusCode
        })
    }

    static downloadHsCodeRawData(hsCode: string, countryManufacture: string, exportingCountry: string, division: string, importer: string,
                                 item: string, osMfgCompany: string, productNameEn: string, productNameKr: string, hsCode2: string, rawMaterials: string,
                                 dateFrom: string, dateTo: string): Promise<AxiosResponse<BaseResponse<null>>> {
        return instance.get(`admin/excelDown/thirdworks/excel`, {
            responseType: "blob",
            params: {
                hsCode: hsCode ? hsCode : null,
                countryManufacture: countryManufacture ? countryManufacture : null,
                exportingCountry: exportingCountry ? exportingCountry : null,
                division: division ? division : null,
                importer: importer ? importer : null,
                item: item ? item : null,
                osMfgCompany: osMfgCompany ? osMfgCompany : null,
                productNameEn: productNameEn ? productNameEn : null,
                productNameKr: productNameKr ? productNameKr : null,
                hsCode2: hsCode2 ? hsCode2 : null,
                rawMaterials: rawMaterials ? rawMaterials : null,
                dateFrom: dateFrom,
                dateTo: dateTo
            }
        })
    }

    static getHsCodeRawData(hsCode: string, countryManufacture: string, exportingCountry: string, division: string, importer: string,
                            item: string, osMfgCompany: string, productNameEn: string, productNameKr: string, hsCode2: string, rawMaterials: string,
                            dateFrom: string, dateTo: string, pageIndex: number): Promise<AxiosResponse<BaseResponse<HsCodeRawData>>> {
        return instance.get(`admin/excelDown/thirdworks`, {
            params: {
                hsCode: hsCode ? hsCode : null,
                countryManufacture: countryManufacture ? countryManufacture : null,
                exportingCountry: exportingCountry ? exportingCountry : null,
                division: division ? division : null,
                importer: importer ? importer : null,
                item: item ? item : null,
                osMfgCompany: osMfgCompany ? osMfgCompany : null,
                productNameEn: productNameEn ? productNameEn : null,
                productNameKr: productNameKr ? productNameKr : null,
                hsCode2: hsCode2 ? hsCode2 : null,
                rawMaterials: rawMaterials ? rawMaterials : null,
                pageIndex: pageIndex,
                countPerPage: 10,
                dateFrom: dateFrom,
                dateTo: dateTo
            }
        })
    }

    static getImportApplyNotices(): Promise<AxiosResponse<BaseResponse<Array<ImportApplyNoticeListItem>>>> {
        return instance.get(`admin/importApply/notices`, {
            params: {
                pageIndex: 0,
                countPerPage: 100
            }
        })
    }

    static getNotice(noticeSequence: number): Promise<AxiosResponse<BaseResponse<ImportApplyNoticeDetail>>> {
        return instance.get(`admin/importApply/notice/${noticeSequence}`)
    }

    static addNotice(subject: string, contents: string): Promise<AxiosResponse<BaseResponse<null>>> {
        return instance.post(`admin/importApply/notice`, {
            subject: subject,
            contents: contents
        })
    }

    static modifyNotice(noticeSequence: number, subject: string, contents: string): Promise<AxiosResponse<BaseResponse<null>>> {
        return instance.put(`admin/importApply/notice/${noticeSequence}`, {
            subject: subject,
            contents: contents
        })
    }

    static deleteNotice(noticeSequence: number): Promise<AxiosResponse<BaseResponse<null>>> {
        return instance.delete(`admin/importApply/notice/${noticeSequence}`)
    }

}