/**
 * @author jaeho.lee104 on 2023. 11. 08..
 */
import {Dispatch} from "redux";
import {AdminAPI} from "../network/AdminAPI";
import {ResponseCodes} from "../network/ResponseCodes";

enum AdminActionType {
    LOGIN = "users/LOGIN",
    PRODUCT_REFRESH = "products/REFRESH",
    COMPANY_REFRESH = "companies/REFRESH"
}

interface AdminLoginAction {
    type: AdminActionType.LOGIN
    signedIn: boolean
}

interface AdminProductRefreshAction {
    type: AdminActionType.PRODUCT_REFRESH
    productRefresh: number
}

interface AdminCompanyRefreshAction {
    type: AdminActionType.COMPANY_REFRESH
    companyRefresh: number
}

type AdminActions = AdminLoginAction
    | AdminProductRefreshAction
    | AdminCompanyRefreshAction

type AdminState = {
    signedIn: boolean
    productRefresh: number
    companyRefresh: number
}

const initialState: AdminState = {
    signedIn: false,
    productRefresh: 0,
    companyRefresh: 0
};

export class Admins {

    static refreshProduct() {
        return {
            type: AdminActionType.PRODUCT_REFRESH,
            productRefresh: 1
        }
    }

    static refreshCompany() {
        return {
            type: AdminActionType.COMPANY_REFRESH,
            companyRefresh: 1
        }
    }

    static adminLogin = (id: string, password: string) => {
        return (dispatch: Dispatch<AdminActions>) =>
            AdminAPI.signIn({
                userId: id,
                password: password
            }).then((response) => {
                const responseCode = ResponseCodes.of(response.data.code);
                if (responseCode.isSuccess()) {
                    dispatch({
                        type: AdminActionType.LOGIN,
                        signedIn: true
                    } as AdminLoginAction)
                } else {
                    alert(`[login failed] code: ${responseCode.code}, message: ${responseCode.message}`)
                    dispatch({
                        type: AdminActionType.LOGIN,
                        signedIn: false
                    } as AdminLoginAction)
                }
            }).catch((e) => {
                dispatch({
                    type: AdminActionType.LOGIN,
                    signedIn: false
                } as AdminLoginAction)
            });
    }
}

function adminReducer(state: AdminState = initialState, action: AdminActions): AdminState {
    switch (action.type) {
        case AdminActionType.LOGIN:
            return {
                ...state,
                signedIn: action.signedIn
            }
        case AdminActionType.PRODUCT_REFRESH:
            return {
                ...state,
                productRefresh: state.productRefresh + 1
            }
        case AdminActionType.COMPANY_REFRESH:
            return {
                ...state,
                companyRefresh: state.companyRefresh + 1
            }
        default:
            return state;
    }
}

export default adminReducer;
