import React, {useEffect, useState} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {RootState} from "../modules";
import {ContentContainer} from "../styledcomponents/Containers";
import FlexBox from "styledcomponents/FlexBox";
import Image from "../styledcomponents/Image";
import styled from "styled-components";
import CommonComponent from "../styledcomponents/CommonComponent";
import {Colors, Fonts} from "../styledcomponents/Styles";
import {KeyboardUtils} from "../utils/KeyboardUtils";
import {useTranslation} from "react-i18next";

/**
 * @author jaeho.lee104 on 2023. 04. 08..
 */

interface NavigationItemProps {
    selected: boolean
}

const NavigationItem = styled(CommonComponent)<NavigationItemProps>`
    min-width: 60px;
    font-weight: ${({selected}) => selected ? Fonts.WEIGHT_700 : Fonts.WEIGHT_500};
    color: ${({selected}) => selected ? Colors.MAIN : Colors.GRAY_8};
    font-size: 16px;
    cursor: pointer;
    margin-right: 32px;
    text-align: left;

    &:hover {
        color: ${Colors.MAIN_LIGHT}
    }
`;

const PcAppBar = () => {
    const {signedIn} = useSelector((state: RootState) => state.usersReducer, shallowEqual);
    const {language} = useSelector((state: RootState) => state.configurationsReducer, shallowEqual);
    const [selectedMenuIndex, setSelectedMenuIndex] = useState(0)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const {t} = useTranslation()

    useEffect(() => {
        let pathname = location.pathname
        if (pathname === "/") {
            setSelectedMenuIndex(0)
        } else if (pathname === "/users") {
            setSelectedMenuIndex(1)
        } else if (pathname === "/products") {
            setSelectedMenuIndex(2)
        } else {
            setSelectedMenuIndex(999)
        }
    }, [location])

    return (
        <>
            <FlexBox
                minWidth={"1200px"}
                width={"100%"}
                borderBottom={`1px solid ${Colors.GRAY_3}`}>
                <ContentContainer
                    width={"100%"}
                    maxWidth={"1600px"}
                    paddingLeft={"20px"}
                    paddingRight={"20px"}>
                    <FlexBox alignItems={"center"}
                             justifyContent={"space-between"}
                             minHeight={"64px"}>
                        <Image
                            width={"205px"}
                            height={"20px"}
                            cursor={"pointer"}
                            src={require("assets/images/app-bar-logo.svg").default}
                            alignSelf={"center"}
                            alt="app-bar-logo"/>
                        <FlexBox
                            alignItems={"center"}>
                            <NavigationItem
                                selected={selectedMenuIndex === 0}
                                onClick={(e) => {
                                    if (KeyboardUtils.isNewWindowTriggerKey(e)) {
                                        window.open("/")
                                    } else {
                                        navigate("/")
                                    }
                                }}>Home</NavigationItem>
                            <NavigationItem
                                selected={selectedMenuIndex === 1}
                                onClick={(e) => {
                                    if (KeyboardUtils.isNewWindowTriggerKey(e)) {
                                        window.open("/users")
                                    } else {
                                        navigate("/users")
                                    }
                                }}>회원관리</NavigationItem>
                            <NavigationItem
                                selected={selectedMenuIndex === 2}
                                onClick={(e) => {
                                    if (KeyboardUtils.isNewWindowTriggerKey(e)) {
                                        window.open("/products?index=0")
                                    } else {
                                        navigate("/products?index=0")
                                    }
                                }}>상품관리</NavigationItem>
                            <NavigationItem
                                selected={selectedMenuIndex === 3}
                                onClick={(e) => {
                                    if (KeyboardUtils.isNewWindowTriggerKey(e)) {
                                        window.open("/companies")
                                    } else {
                                        navigate("/companies")
                                    }
                                }}>기업관리</NavigationItem>
                            <NavigationItem
                                selected={selectedMenuIndex === 4}
                                onClick={(e) => {
                                    if (KeyboardUtils.isNewWindowTriggerKey(e)) {
                                        window.open("/tools")
                                    } else {
                                        navigate("/tools")
                                    }
                                }}>운영툴</NavigationItem>
                        </FlexBox>
                    </FlexBox>
                </ContentContainer>
            </FlexBox>
        </>
    )
}
export default PcAppBar;