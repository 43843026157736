import React, {useEffect, useState} from "react";
import FlexBox from "../styledcomponents/FlexBox";
import {useSearchParams} from "react-router-dom";
import Image from "../styledcomponents/Image";
import NewH6 from "../styledcomponents/text/NewH6";
import {ProductDetailItem} from "../model/ProductDetailItem";
import {AdminAPI} from "../network/AdminAPI";
import NewP2 from "../styledcomponents/text/NewP2";
import {Colors} from "../styledcomponents/Styles";
import NewH5 from "../styledcomponents/text/NewH5";
import NewH3 from "../styledcomponents/text/NewH3";
import NewH2 from "../styledcomponents/text/NewH2";
import NewH7 from "../styledcomponents/text/NewH7";
import Flag from "react-flagkit";
import NewButton from "../styledcomponents/NewButton";
import {ResponseCodes} from "../network/ResponseCodes";
import {ProductStatus} from "../model/ProductStatus";
import {useDispatch} from "react-redux";
import {Admins} from "../modules/Admins";
import {ImagePreview} from "../components/ImagePreview";

const ProductDetailManageFragment = () => {
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams()
    const [productDetailItem, setProductDetailItem] = useState<ProductDetailItem | null>(null)
    const [selectedProductImageUrl, setSelectedProductImageUrl] = useState("")
    const itemId = searchParams.get('itemId') || ""

    const specifications = [
        {
            "title": "Product",
            "content": productDetailItem?.info?.itemName || "",
        },
        {
            "title": "Ingredients",
            "content": productDetailItem?.specification?.ingredients || "",
        },
        {
            "title": "Origin",
            "content": productDetailItem?.summary?.originCode || ""
        },
        {
            "title": "Shelf life",
            "content": `${productDetailItem?.specification?.shelfLife || ""} Months`
        },
        {
            "title": "Taste",
            "content": productDetailItem?.specification?.taste || ""
        },
        {
            "title": "Appearance",
            "content": productDetailItem?.specification?.appearance || ""
        },
        {
            "title": "Application",
            "content": productDetailItem?.specification?.application || ""
        },
        {
            "title": "Allergen info",
            "content": productDetailItem?.specification?.allergensMore || ""
        },
        {
            "title": "Specification",
            "content": "Download",
            "fontWeight": 700,
            "contentColor": Colors.MAIN,
            "clickable": true,
            "downloadable": true
        },
    ]

    const sourcingInformation = [
        {
            "title": "HS CODE",
            "content": productDetailItem?.info?.itemHsCode || ""
        },
        {
            "title": "Grade",
            "content": productDetailItem?.sourcing?.productGradeCode || ""
        },
        {
            "title": "MOQ",
            "titleDescription": "MOQ : Minimum Order Quantity",
            "content": `${productDetailItem?.summary?.moq || ""} ${productDetailItem?.summary?.moqUnitCode || ""}`
        },
        {
            "title": "OEM",
            "titleDescription": "OEM : Original Equipment Manufacturing",
            "content": productDetailItem?.sourcing?.oem ? "Acceptable" : "Not Acceptable"
        },
        {
            "title": "ODM",
            "titleDescription": "ODM : Original Development Manufacturing",
            "content": productDetailItem?.sourcing?.odm ? "Acceptable" : "Not Acceptable"
        },
        {
            "title": "Korea Label",
            "titleDescription": "Korea Label : 한글표시사항으로 판매 및소비용으로 수입되는 식품 및 식기의 경우 필수사항입니다.",
            "content": `${productDetailItem?.sourcing?.koreanLabel ? "Acceptable" : "Not Acceptable"} ${productDetailItem?.sourcing?.buyersCostCode || ""}`
        },
        {
            "title": "Certifications",
            "content": productDetailItem?.certifications.map((item) => item.certificationName).join(", ")
        },
        {
            "title": "Approved Exporter",
            "content": productDetailItem?.sourcing?.approvedExporterId
        },
        {
            "title": "Product Catalog",
            "content": "Download",
            "fontWeight": 700,
            "contentColor": Colors.MAIN,
            "clickable": true,
            "downloadable": true
        },
    ]

    const packingInformation = [
        {
            "title": "Product Packing",
            "content": `${productDetailItem?.packing?.productPacking || ""} ${productDetailItem?.packing?.packingUnitCode || ""}`
        },
        {
            "title": "Loading capacity",
            "content": `${productDetailItem?.packing?.loadingCapacity || ""} ${productDetailItem?.packing?.loadingCapacityUnitCode || ""}`
        },
        {
            "title": "Lead time",
            "content": productDetailItem?.summary?.leadTime || ""
        },
        {
            "title": "Load Port",
            "content": `${productDetailItem?.packing?.loadingCapacity || ""} ${productDetailItem?.packing?.loadingCapacityUnitCode || ""}`
        },
    ]

    useEffect(() => {
        if (!selectedProductImageUrl && productDetailItem && productDetailItem.itemImages.length > 0) {
            if (productDetailItem.itemImages[0].url) {
                setSelectedProductImageUrl(productDetailItem.itemImages[0].url)
            }
        }
    }, [productDetailItem])

    useEffect(() => {
        if (itemId.length === 0) {
            window.location.href = "/";
        }
    }, [itemId])

    useEffect(() => {
        AdminAPI.getSourcingDetail(itemId)
            .then((res) => {
                let data = res.data.data
                setProductDetailItem(data)
            })
    }, [])

    function updateItemStatus(itemStatus: string) {
        AdminAPI.updateProductStatus(itemId, itemStatus)
            .then((res) => {
                let responseCode = ResponseCodes.of(res.data.code)
                if (responseCode.isSuccess()) {
                    dispatch(Admins.refreshProduct())
                    alert("변경 완료")
                } else {
                    alert(`변경 실패: ${JSON.stringify(responseCode)}`)
                }
            })
    }

    function handleDownload(url?: string) {
        if (!url || url.length === 0) {
            alert("파일이 없습니다.")
            return
        }
        ImagePreview.renderImage(url)
    }

    if (!productDetailItem) {
        return <></>
    }

    return <FlexBox
        margin={"0px auto"}
        width={"1200px"}
        flexDirection={"column"}>
        <FlexBox /* content area */
            margin={"40px auto"}
            width={"1200px"}
            flexDirection={"column"}>
            <FlexBox
                width={"100%"}
                padding={"40px"}
                flexDirection={"column"}>
                <FlexBox
                    height={"474px"}>
                    <FlexBox /*image vertical array*/
                        flexDirection={"column"}>
                        {productDetailItem.itemImages.map((item, index) => {
                            return <FlexBox
                                onClick={() => {
                                    setSelectedProductImageUrl(item.url)
                                }}
                                cursor={"pointer"}
                                borderRadius={"12px"}
                                border={`1.5px solid ${Colors.GRAY_3}`}
                                width={"82px"}
                                height={"82px"}
                                alignItems={"center"}
                                marginTop={index >= 1 ? "14px" : "0px"}
                                justifyContent={"center"}>
                                <Image /*selected product image*/
                                    width={"64px"}
                                    height={"64px"}
                                    src={item.url}
                                    alt={`product-image-${index}`}/>
                            </FlexBox>
                        })}
                    </FlexBox>
                    <FlexBox /*selected product image*/
                        width={"474px"}
                        height={"474px"}
                        padding={"20px"}
                        alignSelf={"center"}
                        alignItems={"center"}
                        borderRadius={"12px"}
                        marginLeft={"24px"}
                        cursor={"pointer"}
                        onClick={() => {
                            if (selectedProductImageUrl) {
                                ImagePreview.renderImage(selectedProductImageUrl)
                            }
                        }}
                        border={`1px solid ${Colors.GRAY_3}`}
                        justifyContent={"center"}>
                        <Image
                            width={"100%"}
                            height={"100%"}
                            src={selectedProductImageUrl || require("assets/images/image-place-holder-48.png")}
                            alt="sourcing-sample-product"/>
                    </FlexBox>
                    <FlexBox /*product info*/
                        marginLeft={"40px"}
                        flexDirection={"column"}>
                        <FlexBox /*country*/
                            marginTop={"30px"}
                            visible={!!productDetailItem.summary?.originCode}
                            alignItems={"center"}>
                            <Flag
                                style={{
                                    borderRadius: "2px",
                                    clipPath: "border-box",
                                    marginRight: "10px"
                                }}
                                country={productDetailItem.summary?.originCode}
                                size={32}/>
                            <NewH5
                                marginLeft={"8px"}
                                color={Colors.GRAY_6}>
                                {productDetailItem.summary?.originName}
                            </NewH5>
                        </FlexBox>
                        <NewH3
                            marginTop={"17px"}
                            textAlign={"left"}
                            color={Colors.GRAY_8}>
                            {productDetailItem.info?.itemName}
                        </NewH3>
                        <FlexBox
                            marginTop={"40px"}
                            alignItems={"center"}>
                            <NewH2
                                color={Colors.MAIN}>
                                {productDetailItem.info?.itemPriceUnitCode} {productDetailItem.info?.itemPrice} ~
                            </NewH2>
                            <NewH3
                                marginLeft={"12px"}
                                color={Colors.GRAY_6}>
                                per {productDetailItem.packing?.packingUnitCode}
                            </NewH3>
                        </FlexBox>
                        <FlexBox
                            marginTop={"16px"}
                            alignItems={"center"}>
                            <NewH5
                                color={Colors.GRAY_8}>
                                Samples: {productDetailItem.info?.samplePriceUnitCode} {productDetailItem.info?.samplePrice}
                            </NewH5>
                        </FlexBox>
                        <FlexBox
                            marginTop={"40px"}
                            flexDirection={"column"}>
                            <NewP2
                                color={Colors.GRAY_7}>
                                · Country of origin, {productDetailItem.summary?.originCode}
                            </NewP2>
                            <NewP2
                                marginTop={"2px"}
                                color={Colors.GRAY_7}>
                                · Minimum of quantity(MOQ), {productDetailItem.summary?.moq} {productDetailItem.summary?.moqUnitCode}
                            </NewP2>
                            <NewP2
                                marginTop={"2px"}
                                color={Colors.GRAY_7}>
                                · Packaging, {productDetailItem.summary?.packaging} {productDetailItem.summary?.packagingUnitCode}
                            </NewP2>
                            <NewP2
                                marginTop={"2px"}
                                color={Colors.GRAY_7}>
                                · Lead time, {productDetailItem.summary?.leadTime}
                            </NewP2>
                            <NewP2
                                marginTop={"2px"}
                                color={Colors.GRAY_7}>
                                · Categories: {productDetailItem.categoryList.map((item) => item.categoryName).join(",")}
                            </NewP2>
                        </FlexBox>
                    </FlexBox>
                </FlexBox>
            </FlexBox>
        </FlexBox>
        <FlexBox
            width={"1200px"}
            flexDirection={"column"}>
            <FlexBox
                backgroundColor={Colors.WHITE}
                border={`1px solid ${Colors.GRAY_3}`}
                padding={"40px 40px 48px 40px"}
                width={"100%"}
                borderRadius={"16px"}
                boxShadow={'0px 4px 8px 0px rgba(0, 0, 0, 0.08);'}
                flexDirection={"column"}
                minHeight={"400px"}>
                <NewH3
                    color={Colors.GRAY_8}>
                    Product Description
                </NewH3>
                <FlexBox
                    marginTop={'32px'}
                    justifyContent={"space-between"}>
                    <FlexBox
                        flexDirection={"column"}>
                        <NewH7
                            color={Colors.GRAY_7}>
                            Specification
                        </NewH7>
                        <FlexBox
                            marginTop={"22px"}
                            flexDirection={"column"}
                            width={"552px"}
                            padding={"24px"}
                            backgroundColor={Colors.GRAY_0}
                            borderRadius={"12px"}
                            border={`1px solid ${Colors.GRAY_2}`}>
                            {specifications.map((item, index) => {
                                return <FlexBox
                                    marginTop={index > 0 ? "16px" : "0px"}>
                                    <NewH5
                                        textAlign={"left"}
                                        width={"160px"}
                                        color={Colors.GRAY_8}>
                                        {item.title}
                                    </NewH5>
                                    <FlexBox
                                        onClick={() => {
                                            handleDownload(productDetailItem?.specificationImages[0].url)
                                        }}
                                        alignItems={"center"}>
                                        {item.downloadable && <Image
                                            cursor={item.clickable ? "pointer" : undefined}
                                            marginTop={"2px"}
                                            marginRight={"8px"}
                                            src={require("assets/images/ic-download-xs.svg").default}
                                            alt={`download-${index}`}/>}
                                        <NewH7
                                            textOverflow={"ellipsis"}
                                            whiteSpace={"nowrap"}
                                            overflow={"hidden"}
                                            cursor={item.clickable ? "pointer" : undefined}
                                            fontWeight={item.fontWeight ? item.fontWeight : 400}
                                            color={item.contentColor ? item.contentColor : Colors.GRAY_7}>
                                            {item.content}
                                        </NewH7>
                                    </FlexBox>
                                </FlexBox>
                            })}
                        </FlexBox>
                    </FlexBox>
                    <FlexBox
                        flexDirection={"column"}>
                        <NewH7
                            color={Colors.GRAY_7}>
                            Sourcing Information
                        </NewH7>
                        <FlexBox
                            marginTop={"22px"}
                            flexDirection={"column"}
                            width={"552px"}
                            padding={"24px"}
                            backgroundColor={Colors.GRAY_0}
                            borderRadius={"12px"}
                            border={`1px solid ${Colors.GRAY_2}`}>
                            {sourcingInformation.map((item, index) => {
                                return <FlexBox
                                    marginTop={index > 0 ? "16px" : "0px"}>
                                    <NewH5
                                        textAlign={"left"}
                                        width={"160px"}
                                        color={Colors.GRAY_8}>
                                        {item.title}
                                    </NewH5>
                                    <FlexBox
                                        onClick={() => {
                                            handleDownload(productDetailItem?.catalogs[0].url)
                                        }}
                                        alignItems={"center"}>
                                        {item.downloadable && <Image
                                            cursor={item.clickable ? "pointer" : undefined}
                                            marginTop={"2px"}
                                            marginRight={"8px"}
                                            src={require("assets/images/ic-download-xs.svg").default}
                                            alt={`download-${index}`}/>}
                                        <NewH7
                                            textOverflow={"ellipsis"}
                                            whiteSpace={"nowrap"}
                                            overflow={"hidden"}
                                            cursor={item.clickable ? "pointer" : undefined}
                                            fontWeight={item.fontWeight ? item.fontWeight : 400}
                                            color={item.contentColor ? item.contentColor : Colors.GRAY_7}>
                                            {item.content}
                                        </NewH7>
                                    </FlexBox>
                                </FlexBox>
                            })}
                        </FlexBox>
                    </FlexBox>
                </FlexBox>
            </FlexBox>
            <FlexBox
                backgroundColor={Colors.WHITE}
                border={`1px solid ${Colors.GRAY_3}`}
                padding={"40px 40px 48px 40px"}
                width={"100%"}
                marginTop={'40px'}
                borderRadius={"16px"}
                boxShadow={'0px 4px 8px 0px rgba(0, 0, 0, 0.08);'}
                flexDirection={"column"}
                minHeight={"400px"}>
                <NewH3
                    color={Colors.GRAY_8}>
                    Packing Information
                </NewH3>
                <FlexBox
                    marginTop={'32px'}
                    flexDirection={"column"}>
                    <FlexBox
                        marginTop={"22px"}
                        flexDirection={"column"}
                        width={"100%"}
                        padding={"24px"}
                        backgroundColor={Colors.GRAY_0}
                        borderRadius={"12px"}
                        border={`1px solid ${Colors.GRAY_2}`}>
                        {packingInformation.map((item, index) => {
                            return <FlexBox
                                marginTop={index > 0 ? "16px" : "0px"}>
                                <NewH5
                                    textAlign={"left"}
                                    width={"256px"}
                                    color={Colors.GRAY_8}>
                                    {item.title}
                                </NewH5>
                                <NewH7
                                    textOverflow={"ellipsis"}
                                    whiteSpace={"nowrap"}
                                    overflow={"hidden"}
                                    color={Colors.GRAY_7}>
                                    {item.content}
                                </NewH7>
                            </FlexBox>
                        })}
                        <NewH5
                            marginTop={"16px"}
                            textAlign={"left"}
                            width={"256px"}
                            color={Colors.GRAY_8}>
                            Picture
                        </NewH5>
                        <FlexBox
                            marginTop={"18px"}>
                            {productDetailItem.packingPictures.map((item, index) => {
                                return <FlexBox
                                    cursor={"pointer"}
                                    onClick={() => {
                                        if (item.url) {
                                            ImagePreview.renderImage(item.url)
                                        }
                                    }}
                                    alignItems={"center"}
                                    marginLeft={index > 0 ? "18px" : "0px"}
                                    flexDirection={"column"}>
                                    <Image
                                        border={`1px solid ${Colors.GRAY_3}`}
                                        width={"256px"}
                                        height={"180px"}
                                        borderRadius={"8px"}
                                        src={item.url}
                                        alt={`packing-image-${index}`}/>
                                    <NewP2
                                        marginTop={"12px"}
                                        color={Colors.GRAY_7}>
                                        {item.name}
                                    </NewP2>
                                </FlexBox>
                            })}
                        </FlexBox>
                    </FlexBox>
                </FlexBox>
            </FlexBox>
            <FlexBox
                backgroundColor={Colors.WHITE}
                border={`1px solid ${Colors.GRAY_3}`}
                padding={"40px 40px 48px 40px"}
                width={"100%"}
                marginTop={'40px'}
                borderRadius={"16px"}
                boxShadow={'0px 4px 8px 0px rgba(0, 0, 0, 0.08);'}
                flexDirection={"column"}
                minHeight={"400px"}>
                <NewH3
                    color={Colors.GRAY_8}>
                    Certifications
                </NewH3>
                {productDetailItem.certifications.map((item, index) => {
                    return <FlexBox
                        marginTop={index > 0 ? "24px" : "34px"}
                        borderRadius={"16px"}
                        border={`1px solid ${Colors.GRAY_2}`}
                        backgroundColor={Colors.GRAY_0}
                        width={"100%"}
                        padding={"24px"}>
                        <FlexBox
                            alignItems={"center"}
                            justifyContent={"center"}>
                            <Image
                                border={`1px solid ${Colors.GRAY_3}`}
                                borderRadius={"12px"}
                                width={'100px'}
                                height={"100px"}
                                src={item.certificationFileUrl}
                                alt={`certification-image-${index}`}/>
                        </FlexBox>
                        <FlexBox
                            marginLeft={"24px"}
                            padding={"12px 0px"}
                            flexDirection={"column"}>
                            <NewH6
                                color={Colors.GRAY_8}>
                                {item.certificationName}
                            </NewH6>
                            <NewP2
                                marginTop={"8px"}
                                color={Colors.GRAY_6}>
                                {item.certificationDesc}
                            </NewP2>
                        </FlexBox>
                    </FlexBox>
                })}
            </FlexBox>
        </FlexBox>
        <FlexBox
            padding={"0px 100px"}
            margin={"40px 0px 100px 0px"}>
            <NewButton
                colorType={0}
                onClick={() => {
                    updateItemStatus(ProductStatus.APPROVAL_APPROVED)
                }}>
                승인
            </NewButton>
            <NewButton
                marginLeft={"20px"}
                colorType={3}
                onClick={() => {
                    updateItemStatus(ProductStatus.APPROVAL_REJECTED)
                }}>
                거절
            </NewButton>
            <NewButton
                marginLeft={"20px"}
                colorType={1}
                onClick={() => {
                    updateItemStatus(ProductStatus.PRODUCT_DISPLAY)
                }}>
                강제 게시
            </NewButton>
            <NewButton
                marginLeft={"20px"}
                colorType={1}
                onClick={() => {
                    updateItemStatus(ProductStatus.PUBLICATION_SUSPENDED)
                }}>
                강제 게시 중단
            </NewButton>
        </FlexBox>
    </FlexBox>
}
export default ProductDetailManageFragment;