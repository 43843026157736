import React, {useEffect, useState} from "react";
import FlexBox from "../styledcomponents/FlexBox";
import NewH1 from "../styledcomponents/text/NewH1";
import NewButton from "../styledcomponents/NewButton";
import TextField from "../components/TextField";
import SingleAutoComplete from "../components/SingleAutoComplete";
import {CommonApi} from "../network/CommonApi";
import {ResponseCodes} from "../network/ResponseCodes";
import {CodeModel} from "../model/CodeModel";
import {DatePicker, Table, TimeRangePickerProps} from "antd";
import dayjs, {type Dayjs} from "dayjs";
import {DateFormatUtils} from "../utils/DateFormatUtils";
import {AdminAPI} from "../network/AdminAPI";
import {useNavigate} from "react-router-dom";
import {ColumnsType} from "antd/es/table";
import {Box, Modal, Pagination} from "@mui/material";
import {HsCodeRawItem} from "../model/HsCodeRawItem";
import {Colors} from "../styledcomponents/Styles";
import NewH3 from "../styledcomponents/text/NewH3";
import NewH4 from "../styledcomponents/text/NewH4";
import NewH5 from "../styledcomponents/text/NewH5";

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: `2px solid ${Colors.GRAY_3}`,
    borderRadius: '16px',
    boxShadow: 24,
    p: 4,
};
const HsCodeSearchFragment = () => {

    const navigate = useNavigate()
    const defaultWidth = "140px"
    const {RangePicker} = DatePicker;
    const dateFormat = 'YYYY-MM-DD';
    const rangePresets: TimeRangePickerProps['presets'] = [
        {label: 'Last 30 Days', value: [dayjs().add(-30, 'd'), dayjs()]},
        {label: 'Last 60 Days', value: [dayjs().add(-60, 'd'), dayjs()]},
        {label: 'Last 90 Days', value: [dayjs().add(-90, 'd'), dayjs()]},
        {label: 'Last 180 Days', value: [dayjs().add(-180, 'd'), dayjs()]},
        {label: 'Last 1 Year', value: [dayjs().add(-1, 'y'), dayjs()]},
        {label: 'Last 5 Year', value: [dayjs().add(-5, 'y'), dayjs()]},
    ];
    const [startDate, setStartDate] = useState(() => {
        const currentDate = new Date()
        currentDate.setFullYear(currentDate.getFullYear() - 1);
        return currentDate
    });
    const [endDate, setEndDate] = useState(new Date());

    const [countries, setCountries] = useState<Array<CodeModel>>([])
    const [countryManufacture, setCountryManufacture] = useState<CodeModel | null>(null)
    const [exportingCountry, setExportingCountry] = useState<CodeModel | null>(null)
    const [division, setDivision] = useState("")
    const [importer, setImporter] = useState("")
    const [item, setItem] = useState("")
    const [osMfgCompany, setOsMfgCompany] = useState("")
    const [productNameEn, setProductNameEn] = useState("")
    const [productNameKr, setProductNameKr] = useState("")
    const [hsCode2, setHsCode2] = useState("")
    const [rawMaterials, setRawMaterials] = useState("")
    const [hsCode, setHsCode] = useState("")
    const [tableItems, setTableItems] = useState<Array<HsCodeRawItem>>([])
    const [totalCount, setTotalCount] = useState(0)
    const [selectedHsCodeItem, setSelectedHsCodeItem] = useState<HsCodeRawItem | null>(null)

    const tableColumns: ColumnsType<HsCodeRawItem> = [
        {
            title: 'No',
            dataIndex: "idx",
            align: "center",
            width: "80px"
        },
        {
            title: "구분",
            dataIndex: "division",
            align: "center",
            width: "140px",
            ellipsis: true
        },
        {
            title: 'HSCODE',
            dataIndex: "hsCode",
            align: "center",
            width: "140px"
        },
        {
            title: '수입사',
            dataIndex: "importer",
            align: "center",
            width: "140px",
            ellipsis: true
        },
        {
            title: '제품명(한)',
            dataIndex: "productNmKr",
            align: "center",
            width: "140px",
            ellipsis: true
        },
        {
            title: '제품명(영)',
            dataIndex: "productNmEn",
            align: "center",
            width: "140px",
            ellipsis: true
        },
        {
            title: '품목(유형)',
            dataIndex: "item",
            align: "center",
            width: "140px",
            ellipsis: true
        },
        {
            title: '제조사',
            dataIndex: "osMfgCo",
            align: "center",
            width: "140px",
            ellipsis: true
        },
        {
            title: '제조국',
            dataIndex: "countryManufacture",
            align: "center",
            width: "140px",
            ellipsis: true
        },
        {
            title: '수출국',
            dataIndex: "exportingCountry",
            align: "center",
            width: "140px",
            ellipsis: true
        },
        {
            title: '처리일자',
            dataIndex: "processingDate",
            align: "center",
            width: "140px",
            ellipsis: true
        },
        {
            title: '원재료',
            dataIndex: "rawMaterials",
            align: "center",
            width: "140px",
            ellipsis: true
        }
    ];

    const onRangeChange = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
        if (dates) {
            setStartDate(new Date(dateStrings[0]))
            setEndDate(new Date(dateStrings[1]))
        }
    };

    useEffect(() => {
        CommonApi.getCountries("kr")
            .then((response) => {
                const responseCode = ResponseCodes.of(response.data.code)
                const countries = response.data.data
                if (responseCode.isSuccess() && countries && countries.length > 0) {
                    setCountries(countries.map((item, index) => {
                        return {
                            code: item.countryCode,
                            name: item.countryName
                        }
                    }))
                }
            })
    }, [])

    function searchHsCodeRawData(pageIndex: number) {
        if (!hsCode && !countryManufacture && !exportingCountry && !division && !importer && !item && !osMfgCompany && !productNameKr
            && !productNameEn && !hsCode2 && !rawMaterials) {
            alert("1개 이상의 필드를 입력해주세요.")
            return
        }
        AdminAPI.getHsCodeRawData(
            hsCode, countryManufacture?.code || "", exportingCountry?.code || "",
            division, importer, item, osMfgCompany, productNameEn, productNameKr, hsCode2, rawMaterials,
            DateFormatUtils.toYYYY_MM_DD_dash(startDate.toDateString()), DateFormatUtils.toYYYY_MM_DD_dash(endDate.toDateString()), pageIndex
        ).then((response) => {
            const responseCode = ResponseCodes.of(response.data.code)
            if (responseCode.isNeedLogin()) {
                alert("로그인이 필요합니다.")
                navigate("/login")
                return
            }
            if (!responseCode.isSuccess()) {
                alert("일시적인 오류가 발생하였습니다. 잠시 후 다시 시도해주세요.")
                return;
            }
            const result = response.data.data
            const items = result?.listData
            setTableItems(items ? items : [])
            setTotalCount(result?.totalCount || 0)
        })
    }

    function downloadHsCodeRawData() {
        if (!hsCode && !countryManufacture && !exportingCountry && !division && !importer && !item && !osMfgCompany && !productNameKr
            && !productNameEn && !hsCode2 && !rawMaterials) {
            const answer = window.confirm("아무 조건도 걸지 않을 경우 서버에 부담이 될 수 있습니다. 다운로드를 진행하시겠습니까?")
            if (!answer) {
                return;
            }
        }
        AdminAPI.downloadHsCodeRawData(
            hsCode, countryManufacture?.code || "", exportingCountry?.code || "",
            division, importer, item, osMfgCompany, productNameEn, productNameKr, hsCode2, rawMaterials,
            DateFormatUtils.toYYYY_MM_DD_dash(startDate.toDateString()), DateFormatUtils.toYYYY_MM_DD_dash(endDate.toDateString())
        ).then(response => {
            const responseCode = ResponseCodes.of(response.data.code)
            if (responseCode.isNeedLogin()) {
                alert("로그인이 필요합니다.")
                navigate("/login")
                return
            }
            const type = response.headers['content-type']
            // @ts-ignore
            const blob = new Blob([response.data], {type: type, encoding: 'UTF-8'})
            const link = document.createElement('a')
            const blobUrl = URL.createObjectURL(blob)
            link.href = blobUrl
            var builder = hsCode
            if (countryManufacture) {
                builder += `_${countryManufacture.name}`
            }
            if (exportingCountry) {
                builder += `_${exportingCountry.name}`
            }
            if (division) {
                builder += `_${division}`
            }
            if (importer) {
                builder += `_${importer}`
            }
            if (item) {
                builder += `_${item}`
            }
            if (osMfgCompany) {
                builder += `_${osMfgCompany}`
            }
            if (productNameEn) {
                builder += `_${productNameEn}`
            }
            if (productNameKr) {
                builder += `_${productNameKr}`
            }
            if (hsCode2) {
                builder += `_${hsCode2}`
            }
            if (rawMaterials) {
                builder += `_${rawMaterials}`
            }
            builder += `_${DateFormatUtils.toYYYYMMDD(startDate.toDateString())}`
            builder += `_${DateFormatUtils.toYYYYMMDD(endDate.toDateString())}`
            link.download = `TW_${builder}`
            link.click()
            URL.revokeObjectURL(blobUrl)
        })
    }

    return <FlexBox
        padding={"40px"}
        width={"1200px"}
        margin={"0 auto"}
        flexDirection={"column"}
        overflow={"auto"}>
        <NewH1
            marginTop={"40px"}>
            HS CODE 다운로드
        </NewH1>
        <FlexBox
            marginTop={"40px"}
            flexDirection={"column"}
            flexWrap={"wrap"}>
            <FlexBox>
                <RangePicker
                    separator={"~"}
                    defaultValue={[dayjs(startDate), dayjs(endDate)]}
                    presets={rangePresets}
                    style={{
                        textAlign: "center",
                        width: "300px",
                        height: "44px"
                    }}
                    format={dateFormat}
                    onChange={onRangeChange}
                    size={"large"}/>
                <NewButton
                    height={'100%'}
                    marginLeft={"20px"}
                    onClick={() => {
                        searchHsCodeRawData(0)
                    }}
                    width={"140px"}>
                    조회
                </NewButton>
                <NewButton
                    height={'100%'}
                    marginLeft={"20px"}
                    onClick={() => {
                        downloadHsCodeRawData()
                    }}
                    colorType={3}
                    width={"140px"}>
                    다운로드
                </NewButton>
                <NewButton
                    onClick={() => {
                        setHsCode("")
                        setCountryManufacture(null)
                        setExportingCountry(null)
                        setHsCode2("")
                        setRawMaterials("")
                        setProductNameKr("")
                        setProductNameEn("")
                        setDivision("")
                        setOsMfgCompany("")
                        setImporter("")
                        setItem("")
                        setTotalCount(0)
                        setTableItems([])
                    }}
                    colorType={1}
                    height={'100%'}
                    marginLeft={"20px"}
                    width={"140px"}>
                    전체 클리어
                </NewButton>
            </FlexBox>
            <FlexBox
                marginTop={"20px"}
                alignItems={"flex-end"}>
                <TextField
                    width={defaultWidth}
                    label={'HS CODE(6자리)'}
                    labelFontWight={500}
                    labelFontSize={"16px"}
                    enabledClearButton={true}
                    value={hsCode}
                    type={"text"}
                    maxLength={6}
                    onClear={() => {
                        setHsCode("")
                    }}
                    onChange={(e) => {
                        setHsCode(e.target.value)
                    }}
                />
                <SingleAutoComplete
                    enabledStartAdornment={false}
                    width={defaultWidth}
                    marginLeft={"20px"}
                    labelFontWight={500}
                    labelFontSize={"16px"}
                    limitTags={1}
                    label={"제조국"}
                    items={countries}
                    value={countryManufacture}
                    onChangedValues={(value) => {
                        setCountryManufacture(value)
                    }}
                />
                <SingleAutoComplete
                    enabledStartAdornment={false}
                    width={defaultWidth}
                    marginLeft={"20px"}
                    limitTags={1}
                    labelFontWight={500}
                    labelFontSize={"16px"}
                    label={"수출국"}
                    items={countries}
                    value={exportingCountry}
                    onChangedValues={(value) => {
                        setExportingCountry(value)
                    }}
                />
            </FlexBox>
            <FlexBox
                marginTop={"16px"}>
                <TextField
                    width={defaultWidth}
                    label={'구분'}
                    labelFontWight={500}
                    labelFontSize={"16px"}
                    enabledClearButton={true}
                    hint={"ex:수산물"}
                    value={division}
                    type={"text"}

                    onClear={() => {
                        setDivision("")
                    }}
                    onChange={(e) => {
                        setDivision(e.target.value)
                    }}
                />
                <TextField
                    width={defaultWidth}
                    marginLeft={"20px"}
                    label={'수입사'}
                    labelFontWight={500}
                    labelFontSize={"16px"}
                    enabledClearButton={true}
                    hint={"ex:백호무역"}
                    value={importer}
                    type={"text"}

                    onClear={() => {
                        setImporter("")
                    }}
                    onChange={(e) => {
                        setImporter(e.target.value)
                    }}
                />
                <TextField
                    width={defaultWidth}
                    marginLeft={"20px"}
                    label={'품목(유형)'}
                    labelFontWight={500}
                    labelFontSize={"16px"}
                    enabledClearButton={true}
                    hint={"ex:민물가재(냉동)"}
                    value={item}
                    type={"text"}

                    onClear={() => {
                        setItem("")
                    }}
                    onChange={(e) => {
                        setItem(e.target.value)
                    }}
                />
                <TextField
                    width={defaultWidth}
                    marginLeft={"20px"}
                    label={'제조사'}
                    labelFontWight={500}
                    labelFontSize={"16px"}
                    enabledClearButton={true}
                    hint={"ex:PT. SEAFOOD SUMATERA PERKASA"}
                    value={osMfgCompany}
                    type={"text"}

                    onClear={() => {
                        setOsMfgCompany("")
                    }}
                    onChange={(e) => {
                        setOsMfgCompany(e.target.value)
                    }}
                />
            </FlexBox>
            <FlexBox
                marginTop={"16px"}>
                <TextField
                    width={defaultWidth}
                    label={'제품명(영문)'}
                    labelFontWight={500}
                    labelFontSize={"16px"}
                    enabledClearButton={true}
                    hint={"ex:FROZEN CRAYFISH"}
                    value={productNameEn}
                    type={"text"}
                    onClear={() => {
                        setProductNameEn("")
                    }}
                    onChange={(e) => {
                        setProductNameEn(e.target.value)
                    }}
                />
                <TextField
                    width={defaultWidth}
                    marginLeft={"20px"}
                    label={'제품명(한글)'}
                    labelFontWight={500}
                    labelFontSize={"16px"}
                    enabledClearButton={true}
                    hint={"ex:냉동민물가재"}
                    value={productNameKr}
                    type={"text"}
                    onClear={() => {
                        setProductNameKr("")
                    }}
                    onChange={(e) => {
                        setProductNameKr(e.target.value)
                    }}
                />
                <TextField
                    width={defaultWidth}
                    marginLeft={"20px"}
                    label={'HS CODE(2자리)'}
                    labelFontWight={500}
                    labelFontSize={"16px"}
                    enabledClearButton={true}
                    hint={"ex:03"}
                    value={hsCode2}
                    type={"text"}
                    maxLength={2}
                    onClear={() => {
                        setHsCode2("")
                    }}
                    onChange={(e) => {
                        setHsCode2(e.target.value)
                    }}
                />
                <TextField
                    width={defaultWidth}
                    marginLeft={"20px"}
                    label={'원재료'}
                    labelFontWight={500}
                    labelFontSize={"16px"}
                    enabledClearButton={true}
                    value={rawMaterials}
                    type={"text"}
                    onClear={() => {
                        setRawMaterials("")
                    }}
                    onChange={(e) => {
                        setRawMaterials(e.target.value)
                    }}
                />
            </FlexBox>
            <FlexBox
                width={"100%"}
                overflowX={"auto"}
                visible={totalCount > 0}
                flexDirection={"column"}
                marginTop={"40px"}>
                <Table
                    style={{
                        width: "100%",
                        cursor: "pointer"
                    }}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: (event) => {
                                setSelectedHsCodeItem(record)
                            },
                        };
                    }}
                    columns={tableColumns}
                    dataSource={tableItems}
                    bordered={true}
                    pagination={false}
                />
            </FlexBox>
            <Pagination count={totalCount}
                        style={{
                            display: totalCount > 0 ? "block" : "none",
                        }}
                        sx={{
                            marginTop: "10px"
                        }}
                        siblingCount={5}
                        showFirstButton={true}
                        showLastButton={true}
                        onChange={(event: React.ChangeEvent<unknown>, value: number) => {
                            searchHsCodeRawData(value - 1)
                        }}/>
        </FlexBox>
        <Modal
            open={selectedHsCodeItem !== null}
            onClose={() => {
                setSelectedHsCodeItem(null)
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={modalStyle}>
                <FlexBox
                    flexDirection={"column"}>
                    <NewH3>
                        {selectedHsCodeItem?.hsCode || ""}
                    </NewH3>
                    <NewH4>
                        구분: {selectedHsCodeItem?.division || ""}
                    </NewH4>
                    <NewH4>
                        품목(유형): {selectedHsCodeItem?.item || ""}
                    </NewH4>
                    <NewH4>
                        제조국: {selectedHsCodeItem?.countryManufacture || ""}
                    </NewH4>
                    <NewH4>
                        수출국: {selectedHsCodeItem?.exportingCountry || ""}
                    </NewH4>
                    <NewH4>
                        처리일자: {selectedHsCodeItem?.processingDate || ""}
                    </NewH4>
                    <NewH5>
                        제품명(한): {selectedHsCodeItem?.productNmKr || ""}
                    </NewH5>
                    <NewH5>
                        제품명(영): {selectedHsCodeItem?.productNmEn || ""}
                    </NewH5>
                    <NewH5>
                        수입사: {selectedHsCodeItem?.importer || ""}
                    </NewH5>
                    <NewH5>
                        제조사: {selectedHsCodeItem?.osMfgCo || ""}
                    </NewH5>
                </FlexBox>
            </Box>
        </Modal>
    </FlexBox>
}
export default HsCodeSearchFragment;