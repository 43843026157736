import {createDefaultAxiosInstance} from "./NetworkCommon";
import {AxiosResponse} from "axios";
import {CountryModel} from "../model/CountryModel";

const instance = createDefaultAxiosInstance()

export class CommonApi {
    static getCountries(langCode: string): Promise<AxiosResponse<BaseResponse<Array<CountryModel>>>> {
        return instance.get("/common/country", {
            params: {
                langCode: langCode
            },
        });
    }
}