/**
 * @author jaeho.lee104 on 2024. 02. 02..
 */
import {useNavigate, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import FlexBox from "../styledcomponents/FlexBox";
import {Colors, Sizes} from "../styledcomponents/Styles";
import NewH4 from "../styledcomponents/text/NewH4";
import VerticalDivider from "../styledcomponents/VerticalDivider";
import SolutionUserManagerFragment from "./SolutionUserManageFragment";
import AllUsersFragment from "./AllUsersFragment";

const UserManagerFragment = () => {

    const [searchParams, setSearchParams] = useSearchParams()
    const [selectedMenuIndex, setSelectedMenuIndex] = useState(Number(searchParams.get('index') || '0'))
    const navigate = useNavigate()

    useEffect(() => {
        setSelectedMenuIndex(Number(searchParams.get('index') || String(selectedMenuIndex)))
    }, [searchParams])
    return (
        <FlexBox
            width={"100%"}
            minWidth={Sizes.CONTENT_WIDTH}
            height={"100%"}>
            <FlexBox
                flexDirection={"column"}
                minWidth={"240px"}
                borderRight={`2px solid ${Colors.GRAY_2}`}
                backgroundColor={Colors.WHITE}
                padding={"48px 40px"}>
                <FlexBox
                    flexDirection={"column"}>
                    <FlexBox
                        marginTop={"24px"}>
                        <VerticalDivider
                            backgroundColor={Colors.GRAY_3}
                            height={"100%"}
                            width={"4px"}/>
                        <FlexBox
                            flexDirection={"column"}>
                            <NewH4
                                color={selectedMenuIndex === 0 ? Colors.GRAY_8 : Colors.GRAY_4}
                                cursor={"pointer"}
                                onClick={() => {
                                    navigate("/users?index=0")
                                }}
                                marginLeft={"16px"}>
                                전체 회원 목록
                            </NewH4>
                            <NewH4
                                color={selectedMenuIndex === 1 ? Colors.GRAY_8 : Colors.GRAY_4}
                                cursor={"pointer"}
                                marginTop={"16px"}
                                onClick={() => {
                                    navigate("/users?index=1")
                                }}
                                marginLeft={"16px"}>
                                회원 심사 관리
                            </NewH4>
                        </FlexBox>
                    </FlexBox>
                </FlexBox>
            </FlexBox>
            <FlexBox
                flexGrow={1}>
                {selectedMenuIndex === 0 ? <AllUsersFragment/> : <SolutionUserManagerFragment/>}
            </FlexBox>
        </FlexBox>
    )
}
export default UserManagerFragment;