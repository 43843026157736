/**
 * @author jaeho.lee104 on 2023. 08. 23..
 */
import React, {useEffect, useState} from "react";
import {Dialog} from "@mui/material";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import FlexBox from "../styledcomponents/FlexBox";
import NewH3 from "../styledcomponents/text/NewH3";
import TextField from "../components/TextField";
import styled from "styled-components";
import TextArea from "antd/lib/input/TextArea";
import {Colors} from "../styledcomponents/Styles";
import NewButton from "../styledcomponents/NewButton";
import {AdminAPI} from "../network/AdminAPI";
import {ResponseCodes} from "../network/ResponseCodes";
import Text from "../styledcomponents/Text";
import SimpleDialog, {ButtonType} from "./SimpleDialog";


const ContentTextArea = styled(TextArea)`
    .ant-input::placeholder {
        color: ${Colors.GRAY_6};
    }
`;

const theme = createTheme({
    components: {
        MuiDialog: {
            styleOverrides: {
                paper: {
                    minWidth: "800px",
                    borderRadius: "24px"
                }
            }
        }
    },
    palette: {
        background: {
            paper: '#fff',
        },
        text: {
            primary: '#000',
            secondary: '#46505A',
        },
        action: {
            active: '#001E3C',
        },
    },
});

export interface ImportApplyNoticeDialogProps {
    noticeSequence: number,
    isOpen: boolean,
    onClose: (positive: boolean) => void
}

const ImportApplyNoticeDialog: React.FC<{
    dialogProps: ImportApplyNoticeDialogProps
}> = ({dialogProps}) => {

    const [isOpen, setIsOpen] = useState(false);
    const [subject, setSubject] = useState("")
    const [content, setContent] = useState("")
    const isModify = dialogProps.noticeSequence > -1
    const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState(false)


    useEffect(() => {
        setIsOpen(dialogProps.isOpen);
    }, [dialogProps.isOpen]);

    useEffect(() => {
        if (dialogProps.noticeSequence === -1) {
            setSubject("")
            setContent("")
        } else {
            AdminAPI.getNotice(dialogProps.noticeSequence)
                .then((response) => {
                    const data = response.data.data
                    setSubject(data?.subject || "")
                    setContent(data?.contents || "")
                })
        }
    }, [dialogProps.noticeSequence]);

    const handleOk = () => {
        if (!isOpen) {
            return;
        }
        dialogProps.onClose(true);
    };

    const handleCancel = () => {
        if (!isOpen) {
            return;
        }
        dialogProps.onClose(false);
    };

    function handleDelete() {
        AdminAPI.deleteNotice(dialogProps.noticeSequence)
            .then((response) => {
                const responseCode = ResponseCodes.of(response.data.code)
                if (responseCode.isSuccess()) {
                    alert("삭제 완료")
                    handleOk()
                } else {
                    alert("삭제 실패")
                }
            })
    }

    function handleSubmit() {
        if (!subject) {
            alert("subject를 입력해주세요.")
            return
        }
        if (!content) {
            alert("content를 입력해주세요.")
            return;
        }
        if (isModify) {
            AdminAPI.modifyNotice(dialogProps.noticeSequence, subject, content)
                .then((response) => {
                    const responseCode = ResponseCodes.of(response.data.code)
                    if (responseCode.isSuccess()) {
                        alert("수정 완료")
                        handleOk()
                    } else {
                        alert("수정 실패")
                    }
                })
        } else {
            AdminAPI.addNotice(subject, content)
                .then((response) => {
                    const responseCode = ResponseCodes.of(response.data.code)
                    if (responseCode.isSuccess()) {
                        alert("등록 완료")
                        handleOk()
                    } else {
                        alert("등록 실패")
                    }
                })
        }
    }

    return <ThemeProvider theme={theme}>
        <Dialog
            open={isOpen}
            onClose={handleCancel}
            onKeyDown={(e) => {
                if (e.key === "Enter") {
                    handleOk();
                }
            }}>
            <FlexBox
                padding={"80px"}
                flexDirection={"column"}>
                <NewH3>
                    {`공지 ${isModify ? "수정" : "등록"}`}
                </NewH3>
                <TextField
                    width={"100%"}
                    label={'Subject'}
                    labelFontWight={700}
                    labelFontSize={"16px"}
                    marginTop={"34px"}
                    value={subject}
                    onChange={(e) => {
                        setSubject(e.target.value)
                    }}
                    type={"text"}
                />
                <Text fontSize={'16px'}
                      color={Colors.GRAY_7}
                      lineHeight={"150%"}
                      marginTop={"34px"}
                      alignSelf={"flex-start"}
                      fontWeight={700}>Content
                </Text>
                <ContentTextArea
                    value={content}
                    onChange={(e) => {
                        setContent(e.target.value)
                    }}
                    showCount
                    placeholder={`공지 내용 작성`}
                    maxLength={1000}
                    style={{
                        height: 250,
                        marginTop: "8px",
                        resize: 'none',
                        padding: "8px",
                    }}
                />
                <FlexBox
                    alignSelf={"flex-end"}
                    marginTop={"40px"}
                    alignItems={"center"}>
                    <NewButton
                        visible={isModify}
                        width={"120px"}
                        colorType={3}
                        onClick={() => {
                            setIsRemoveDialogOpen(true)
                        }}>
                        삭제
                    </NewButton>
                    <NewButton
                        marginLeft={"20px"}
                        width={"120px"}
                        colorType={1}
                        onClick={() => {
                            handleCancel()
                        }}>
                        취소
                    </NewButton>
                    <NewButton
                        marginLeft={"20px"}
                        width={"120px"}
                        colorType={0}
                        onClick={() => {
                            handleSubmit()
                        }}>
                        {isModify ? "수정" : "등록"}
                    </NewButton>
                </FlexBox>
            </FlexBox>
            <SimpleDialog dialogProps={{
                isOpen: isRemoveDialogOpen,
                title: `해당 공지를 삭제하시겠습니까?`,
                description: "",
                positiveText: `삭제`,
                positiveColorType: 3,
                negativeColorType: 1,
                buttonType: ButtonType.BOTH,
                onClose: (positive) => {
                    setIsRemoveDialogOpen(false)
                    if (positive) {
                        handleDelete()
                    }
                }
            }}/>
        </Dialog>
    </ThemeProvider>
}
export default ImportApplyNoticeDialog